<template>
  <div class="container">
    <MobileBreadcrumbs :current-page="__('profile-questions.index.title')" />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4 mb-6">
        <DesktopBreadcrumbs :current-page="__('profile-questions.index.title')" />

        <div class="bg-white shadow-box rounded p-3 md:p-0 mb-4 md:shadow-none w-full">
          <h1 class="font-serif text-red mb-2 font-bold md:text-3xl">
            {{ __('profile-questions.index.title') }}
          </h1>
          <p class="text-sm font-bold">
            {{ __('profile-questions.index.text') }}
          </p>
        </div>

        <section class="container flex flex-col mb-6">
          <h2 class="text-lg text-white md:text-black font-bold text-center mb-4">
            {{ __('profile-questions.index.questions.title') }}
          </h2>

          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
            <div
              v-if="hasOpenQuestions"
              class="relative bg-white shadow-box rounded p-3 mb-2 md:mb-0 flex flex-wrap"
            >
              <div class="absolute top-3 right-4 flex rounded-full bg-gray-100 text-gray-200 border-2 border-gray-200 p-1.5">
                <span class="material-symbols-outlined font-bold">check</span>
              </div>
              <div class="absolute -top-2 left-4 rounded bg-gray-200 text-black text-2xs px-2 py-0.5 border border-white">
                {{ __('profile-questions.index.questions.open.tag') }}
              </div>

              <div>
                <h3 class="text-red font-serif font-bold mb-3 block w-full">
                  {{ __('profile-questions.index.questions.open.title') }}
                </h3>
                <p>
                  {{ __('profile-questions.index.questions.open.text') }}
                </p>
              </div>

              <div class="flex self-end w-full">
                <Link
                  class="btn mt-3 text-sm md:text-base"
                  :href="route('frontend.profile-questions.open')"
                >
                  {{ __('profile-questions.index.questions.open.link') }}
                  <span class="material-symbols-outlined text-white font-bold">
                    chevron_right
                  </span>
                </Link>
              </div>
            </div>

            <div
              v-if="hasCompletedQuestions"
              class="relative bg-white shadow-box rounded p-3 mb-2 md:mb-0 flex flex-wrap"
            >
              <div class="absolute -top-2 left-4 rounded bg-red text-white text-2xs px-2 py-0.5 border border-white">
                {{ __('profile-questions.index.questions.completed.tag') }}
              </div>

              <div class="absolute top-3 right-4 flex rounded-full bg-red/10 text-red border-2 border-red p-1.5">
                <span class="material-symbols-outlined font-bold">check</span>
              </div>

              <div>
                <h3 class="text-red font-serif font-bold mb-3 block w-full">
                  {{ __('profile-questions.index.questions.completed.title') }}
                </h3>
                <p>
                  {{ __('profile-questions.index.questions.completed.text') }}
                </p>
              </div>

              <div class="flex self-end w-full">
                <Link
                  class="btn mt-3 text-sm md:text-base"
                  :href="route('frontend.profile-questions.completed')"
                >
                  {{ __('profile-questions.index.questions.completed.link') }}
                  <span class="material-symbols-outlined text-white font-bold">
                    chevron_right
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section class="container mb-6">
          <p class="text-xs text-white md:text-black">
            <i18n-t
              keypath="profile-questions.index.footer-text"
              scope="global"
            >
              <template #contactEmail>
                <a
                  :href="`mailto:${contactEmail}`"
                  class="text-blue underline hover:no-underline"
                >
                  {{ contactEmail }}
                </a>
              </template>
            </i18n-t>
          </p>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hasOpenQuestions: {
      type: Boolean,
      required: true,
    },

    hasCompletedQuestions: {
      type: Boolean,
      required: true,
    },

    contactEmail: {
      type: String,
      required: true,
    },
  },
};
</script>


<template>
  <div class="container">
    <MobileBreadcrumbs :current-page="__('free-action.title')" />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4">
        <div class="mb-6">
          <DesktopBreadcrumbs :current-page="__('free-action.title')" />
        </div>

        <h1 class="text-white text-center md:text-left font-bold font-serif md:text-red md:text-3xl mb-4">
          {{ __('free-action.top') }}
        </h1>

        <template v-if="availableFreeActions">
          <div class="grid grid-cols-2 xl:grid-cols-4 gap-3">
            <FreeActionBlock
              v-for="freeAction in availableFreeActions"
              :key="`free-action-${freeAction.id}`"
              :free-action="freeAction"
            />
          </div>
        </template>
        <template v-else>
          <div class="mb-12">
            <p class="font-medium">
              <i>{{ __('free-action.no-free-actions') }}</i>
            </p>
          </div>
        </template>
      </section>
    </div>
  </div>
</template>

<script>
import FreeActionBlock from '../../Components/FreeActionBlock.vue';

export default {
  components: {
    FreeActionBlock,
  },

  props: {
    availableFreeActions: {
      type: Array,
      required: true,
    },
  },
};
</script>

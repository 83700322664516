<template>
  <div class="container">
    <MobileBreadcrumbs :current-page="__('balance.title')" />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4 mb-6">
        <DesktopBreadcrumbs :current-page="__('balance.title')" />

        <div class="bg-white shadow-box rounded p-3 md:p-0 mb-4 md:shadow-none w-full">
          <h1 class="font-serif text-red mb-2 font-bold md:text-3xl">
            {{ __('balance.current-balance') }}
          </h1>

          <span class="text-sm md:text-base block">
            {{ $page.props.auth.member.money_balance }} - {{ $page.props.auth.member.points_balance }}
          </span>

          <template v-if="member.canRequestPayout">
            <Link
              :href="route('frontend.payout')"
              class="btn mt-3 text-sm md:text-base md:w-1/2 md:mb-4"
            >
              {{ __('balance.payout-button') }}
              <span class="material-symbols-outlined text-white font-bold">
                chevron_right
              </span>
            </Link>
          </template>
          <template v-else>
            <span class="text-sm md:text-base">
              {{ __('balance.minimum-payout-info', {minimum: member.minimumPayoutValue}) }}
            </span>
          </template>
        </div>

        <h2 class="text-white font-bold font-serif text-center md:text-black md:text-2xl md:text-left block w-full">
          {{ __('balance.cashback.title') }}
        </h2>

        <div class="flex flex-wrap text-white mt-4 mb-2 md:text-black text-xs md:text-sm w-full">
          <span class="w-7/12 md:w-8/12 font-bold pl-3 md:pl-0">
            {{ __('balance.cashback.description') }}
          </span>
          <span class="w-2/12 md:w-2/12 font-bold pl-3 md:pl-0">
            {{ __('balance.cashback.amount') }}
          </span>
          <span class="w-3/12 md:w-2/12 font-bold pl-3 md:pl-0">
            {{ __('balance.cashback.total') }}
          </span>
        </div>

        <div
          class="bg-white rounded md:rounded-none divide-solid divide-y divide-yellow-500 mb-6 md:border-t md:border-yellow-500 w-full"
        >
          <template
            v-for="(_, key) in balances"
            :key="`balance-group-${key}`"
          >
            <BalanceGroupRow
              :group="key"
              :title="__(`balance.cashback.type.${key}`)"
              :data="balances[key]"
            />
          </template>

          <BalanceGroupRow
            v-if="oldBalancePoints !== null"
            group="old-balance"
            :title="__('balance.cashback.type.old_balance')"
            :data="{count: 1, balance: oldBalancePoints, hasDetails: false}"
          />

          <div class="flex flex-wrap py-4 justify-end">
            <span class="pl-3 md:pl-0 font-bold">
              {{ __('balance.cashback.total-cashback') }}
            </span>
            <span class="pr-3 pl-3">
              <span class="font-bold text-red">
                {{ member.totalEarnedBalancePoints }}
              </span>
              <span>
                ({{ member.totalEarnedBalance }})
              </span>
            </span>
          </div>
        </div>

        <h2 class="text-white font-bold font-serif text-center md:text-black md:text-2xl md:text-left block w-full">
          {{ __('balance.payouts.title') }}
        </h2>

        <div class="flex flex-wrap text-white mt-4 mb-2 md:text-black text-xs md:text-sm w-full">
          <span class="w-5/12 md:w-2/12 font-bold pl-3 md:pl-0">
            {{ __('balance.payouts.date') }}
          </span>
          <span class="w-4/12 md:w-2/12 font-bold pl-3 md:pl-0">
            {{ __('balance.payouts.type') }}
          </span>
          <span class="w-6/12 hidden md:inline font-bold pl-3 md:pl-0">
            {{ __('balance.payouts.status') }}
          </span>
          <span class="w-3/12 md:w-2/12 font-bold pl-3 md:pl-0">
            {{ __('balance.payouts.amount') }}
          </span>
        </div>

        <div
          class="bg-white rounded md:rounded-none divide-solid divide-y divide-yellow-500 md:border-t md:border-yellow-500 w-full"
        >
          <template v-if="payouts.data && payouts.data.length > 0">
            <template
              v-for="payout in payouts.data"
              :key="`payout-${payout.id}`"
            >
              <div class="flex flex-wrap py-2 items-center">
                <span class="w-5/12 md:w-2/12 pl-3 md:pl-0">
                  <div>
                    {{ payout.date }}

                    <div class="inline-flex items-center md:hidden text-sm">
                      <div :class="`dot ${payoutStatus(payout)} mr-2`" />
                      {{ __(`balance.status.${payoutStatus(payout)}`) }}
                    </div>
                  </div>
                </span>
                <span class="w-4/12 md:w-2/12 pl-3 md:pl-0">
                  {{ payout.type }}
                </span>
                <span class="w-6/12 items-center hidden md:inline-flex pl-3 md:pl-0">
                  <div :class="`dot ${payoutStatus(payout)} mr-2`" />
                  {{ __(`balance.status.${payoutStatus(payout)}`) }}
                </span>
                <span class="w-3/12 md:w-2/12 pl-3 md:pl-0 leading-5">
                  <button
                    class="text-left text-red underline hover:no-underline"
                    @click="openPayoutDetailsModal(payout)"
                  >
                    {{ payout.value }}
                  </button>
                </span>
              </div>
            </template>
          </template>
          <template v-else>
            <div class="flex flex-wrap py-2">
              <span class="w-full text-center text-xs leading-5 md:text-base">
                {{ __('balance.payouts.no-payouts') }}
              </span>
            </div>
          </template>

          <div
            v-if="payouts.meta.pagination.links.length > 3"
            class="text-sm w-full p-3"
          >
            <div
              class="border-gray-200 border flex flex-wrap justify-center divide-solid divide-x divide-gray-200 items-center bg-white md:w-3/6 md:mx-auto"
            >
              <template
                v-for="(link, i) in payouts.meta.pagination.links"
                :key="`payout-page-link-${i}`"
              >
                <Link
                  v-if="link.label === __('pagination.previous')"
                  :href="(link.url ?? payouts.meta.pagination.first_page_url)"
                  class="w-1/12 text-center py-2"
                  :class="{ 'font-bold': link.active }"
                  :only="['payouts']"
                  preserve-scroll
                >
                  <span class="material-symbols-outlined align-middle">
                    chevron_left
                  </span>
                </Link>
                <Link
                  v-else-if="link.label === __('pagination.next')"
                  :href="(link.url ?? payouts.meta.pagination.last_page_url)"
                  class="w-1/12 text-center py-2"
                  :class="{ 'font-bold': link.active }"
                  :only="['payouts']"
                  preserve-scroll
                >
                  <span class="material-symbols-outlined align-middle">
                    chevron_right
                  </span>
                </Link>
                <Link
                  v-else
                  :href="link.url"
                  class="w-1/12 text-center py-2"
                  :class="{ 'font-bold': link.active }"
                  :only="['payouts']"
                  preserve-scroll
                >
                  {{ link.label }}
                </Link>
              </template>
            </div>
          </div>

          <div class="flex flex-wrap py-4 justify-end">
            <span class="pl-3 md:pl-0 font-bold">
              {{ __('balance.payouts.total-payouts') }}
            </span>
            <span class="pr-3 pl-3">
              <span class="font-bold text-red">
                {{ member.totalPayoutsPoints }}
              </span>
              <span>
                ({{ member.totalPayouts }})
              </span>
            </span>
          </div>
        </div>
      </section>
    </div>
  </div>

  <PayoutDetailsModal
    :open="payoutDetailsOpen"
    :payout="selectedPayout"
  />
</template>

<script>
import {ref} from 'vue';
import BalanceGroupRow from './BalanceGroupRow.vue';
import PayoutDetailsModal from './PayoutDetailsModal.vue';

export default {
  components: {
    BalanceGroupRow, PayoutDetailsModal,
  },

  props: {
    member: {
      type: Object,
      required: true,
    },

    oldBalancePoints: {
      type: String,
      required: false,
      default: null,
    },

    balances: {
      type: Object,
      required: true,
    },

    payouts: {
      type: Object,
      required: true,
    },
  },

  setup() {
    return {
      payoutDetailsOpen: ref(false),
      selectedPayout: ref(null),
    };
  },

  methods: {
    openPayoutDetailsModal(payout) {
      this.selectedPayout = payout;
      this.payoutDetailsOpen = true;
    },

    payoutStatus(payout) {
      if (payout.status === 'disapproved') {
        return 'disapproved';
      }

      if (payout.paid_status === 'done') {
        return 'approved';
      }

      return 'pending';
    },
  },
};
</script>

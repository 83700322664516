<template>
  <div class="container">
    <MobileBreadcrumbs :current-page="theme.name" />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4">
        <div class="mb-6">
          <DesktopBreadcrumbs :current-page="theme.name" />

          <div class="w-full mb-4">
            <img
              v-if="theme.media1"
              :src="theme.media1.url"
              class="aspect-[728/90]"
            >
          </div>

          <h1 class="text-white font-bold font-serif mb-2 text-center md:text-red md:text-3xl md:text-left">
            {{ theme.name }}
          </h1>

          <div class="text-white text-center mb-4 md:text-black md:text-left">
            {{ theme.description }}
          </div>
        </div>

        <div
          v-if="Object.keys(sortOptions).length > 1"
          class="w-full mb-6"
        >
          <form
            class="w-full flex md:justify-end"
            @submit.prevent="filter"
          >
            <div class="flex w-1/2 md:w-1/4">
              <label class="block w-full">
                <span class="text-2xs font-bold mb-1.5">
                  {{ __('webshops.filter.sort-on') }}
                </span>
                <select
                  v-model="form.sort"
                  class="block w-full rounded-full border border-yellow text-gray"
                  @change="filter"
                >
                  <option
                    v-for="(value, key) in sortOptions"
                    :key="`sort-option-${key}`"
                    :value="key"
                  >
                    {{ value }}
                  </option>
                </select>
              </label>
            </div>
          </form>
        </div>

        <div class="grid grid-cols-2 xl:grid-cols-4 gap-3">
          <WebshopBlock
            v-for="(webshop, i) in webshops"
            :key="`webshop-${i}`"
            :webshop="webshop"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import WebshopBlock from '../../Components/Webshop/Block.vue';

export default {
  components: {
    WebshopBlock,
  },

  props: {
    theme: {
      type: Object,
      required: true,
    },

    webshops: {
      type: Object,
      required: true,
    },

    sortOptions: {
      type: Object,
      required: true,
    },

    filters: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const form = useForm({
      sort: props.filters.sort,
      theme: props.theme ? props.theme.slug : null,
    });

    return {
      form,
    };
  },

  methods: {
    filter() {
      this.$inertia.get(this.route('frontend.webshop-theme.show', {webshopTheme: this.form.theme}), {
        sort: this.form.sort,
      });
    },
  },
};
</script>

<template>
  <div class="container">
    <MobileBreadcrumbs :current-page="__('faq.index.title')" />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4 mb-6">
        <div class="flex flex-wrap">
          <div class="md:p-0 mb-4 w-full">
            <DesktopBreadcrumbs :current-page="__('faq.index.title')" />

            <div
              v-for="(category, i) in categories"
              :key="`category-${i}`"
              class="mt-6 space-y-2"
            >
              <h2 class="font-serif text-white md:text-red uppercase mb-2 font-bold text-sm md:text-1xl">
                {{ category.name }}
              </h2>
              <template
                v-for="(item, j) in category.items"
                :key="`category-${i}-item-${j}`"
              >
                <Disclosure
                  v-slot="{ open }"
                  as="div"
                  class="bg-white shadow-box md:shadow-none md:border-2 md:border-red rounded pl-3 pr-2 py-2"
                >
                  <DisclosureButton class="flex justify-between items-center w-full">
                    <div class="text-left font-bold">
                      {{ item.question }}
                    </div>
                    <div class="grow text-right pl-3">
                      <span
                        v-show="open"
                        class="material-symbols-outlined align-middle"
                      >
                        expand_less
                      </span>
                      <span
                        v-show="!open"
                        class="material-symbols-outlined align-middle"
                      >
                        expand_more
                      </span>
                    </div>
                  </DisclosureButton>
                  <DisclosurePanel class="pt-2">
                    {{ item.answer }}
                  </DisclosurePanel>
                </Disclosure>
              </template>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {Disclosure, DisclosureButton, DisclosurePanel} from '@headlessui/vue';

export default {
  components: { DisclosurePanel, DisclosureButton, Disclosure },

  props: {
    categories: {
      type: Object,
      required: true,
    },
  },
};
</script>

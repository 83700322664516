<template>
  <div class="form">
    <h3 class="text-lg font-bold mb-2">
      {{ question }}
      <template v-if="maxSelectedAnswers">
        {{ __('profile-questions.questions.max-selected-answers-title', {count: maxSelectedAnswers}) }}
      </template>
    </h3>
    <div>
      <select v-model="model">
        <option
          :value="null"
          selected
          disabled
        >
          {{ __('profile-questions.questions.placeholder') }}
        </option>
        <option
          v-for="answer in answers"
          :key="answer.id"
          :value="answer.id"
        >
          {{ answer.answer }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Array,
      required: false,
      default: () => [],
    },

    question: {
      type: String,
      required: true,
    },

    maxSelectedAnswers: {
      type: Number,
      required: false,
      default: 0,
    },

    answers: {
      type: Object,
      required: true,
    },
  },

  emits: ['update:modelValue'],

  computed:{
    model:{
      get() {
        return this.modelValue[0] ?? null;
      },

      set(value) {
        this.$emit('update:modelValue', [value]);
      },
    },
  },
};
</script>

<template>
  <div class="flex flex-wrap py-2 items-center">
    <span class="w-7/12 md:w-8/12 pl-3 md:pl-0">
      {{ title }}
    </span>
    <span class="w-2/12 md:w-2/12 pl-3 md:pl-0">
      {{ data.count }}x
    </span>
    <span class="w-3/12 md:w-2/12 pl-3 md:pl-0 leading-5">
      <template v-if="data.count > 0">
        <a
          v-if="data.url"
          :href="data.url"
          :target="data.target ?? '_blank'"
          class="text-left text-red underline hover:no-underline"
        >
          {{ data.balance }}
        </a>
        <button
          v-else-if="!Object.keys(data).includes('hasDetails') || data.hasDetails"
          type="button"
          class="text-left text-red underline hover:no-underline"
          @click="toggleGroup"
        >
          {{ data.balance }}
        </button>
        <span v-else>
          {{ data.balance }}
        </span>
      </template>
      <template v-else>
        {{ data.balance }}
      </template>
    </span>
  </div>

  <div
    v-show="open"
    class="divide-solid divide-y divide-gray-200 border-b-0 bg-gray-100"
  >
    <template v-if="loading">
      <div class="text-center">
        <span class="material-symbols-outlined m-8 text-red animate-spin">
          autorenew
        </span>
      </div>
    </template>
    <template v-else>
      <div class="flex flex-wrap text-xs md:text-sm w-full py-2">
        <span class="w-6/12 md:w-8/12 font-bold pl-3 md:pl-2">
          {{ group === 'games' ? __('balance.cashback.row.game') : __('balance.cashback.row.description') }}
        </span>
        <span class="w-3/12 md:w-2/12 font-bold pl-3 md:pl-0">
          {{ __('balance.cashback.row.date') }}
        </span>
        <span class="w-3/12 md:w-2/12 font-bold pl-3 md:pl-0">
          {{ __('balance.cashback.row.amount') }}
        </span>
      </div>

      <template
        v-for="(row, i) in details.data ?? []"
        :key="`${group}-row-${i}`"
      >
        <div class="flex flex-wrap text-sm w-full py-2">
          <span class="w-6/12 md:w-8/12 pl-3 md:pl-2">
            {{ row.name }}
          </span>
          <span class="w-3/12 md:w-2/12 pl-3 md:pl-0">
            {{ row.date }}
          </span>
          <span class="w-3/12 md:w-2/12 pl-3 md:pl-0">
            {{ row.value }}
          </span>
        </div>
      </template>

      <div
        v-if="details?.meta?.pagination?.links.length > 3"
        class=" text-sm w-full p-3"
      >
        <div class="border-gray-200 border flex flex-wrap justify-center divide-solid divide-x divide-gray-200 items-center bg-white md:w-3/6 md:mx-auto">
          <template
            v-for="(link, i) in details?.meta?.pagination?.links ?? []"
            :key="`${group}-page-link-${i}`"
          >
            <button
              v-if="link.label === __('pagination.previous')"
              class="w-1/12 text-center py-2"
              :class="{
                'font-bold': link.active,
                'pointer-events-none': details.meta.pagination.current_page === 1,
              }"
              @click="loadDetails(--details.meta.pagination.current_page)"
            >
              <span class="material-symbols-outlined align-middle">
                chevron_left
              </span>
            </button>
            <button
              v-else-if="link.label === __('pagination.next')"
              class="w-1/12 text-center py-2"
              :class="{
                'font-bold': link.active,
                'pointer-events-none': details.meta.pagination.current_page === details.meta.pagination.last_page,
              }"
              @click="loadDetails(++details.meta.pagination.current_page)"
            >
              <span class="material-symbols-outlined align-middle">
                chevron_right
              </span>
            </button>
            <button
              v-else
              class="w-1/12 text-center py-2"
              :class="{ 'font-bold': link.active }"
              @click="loadDetails(link.label)"
            >
              {{ link.label }}
            </button>
          </template>
        </div>
      </div>

      <div class="flex flex-wrap text-sm w-full py-2 items-center">
        <span class="w-6/12 md:w-8/12 pl-3 md:pl-3 font-bold">
          Totaal
        </span>
        <span class="w-3/12 md:w-2/12 pl-3 md:pl-0">
          {{ data.count }}x
        </span>
        <span class="w-3/12 md:w-2/12 pl-3 md:pl-0">
          {{ data.balance }}
        </span>
      </div>
    </template>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    group: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      open: false,
      loading: true,
      details: {},
    };
  },

  methods: {
    toggleGroup() {
      this.open = !this.open;

      if (this.open) {
        this.loadDetails(1);
      }
    },

    loadDetails(page) {
      this.loading = true;

      axios.get(this.route('frontend.api.balance.details', {page, group: this.group}))
        .then(({data}) => {
          this.details = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

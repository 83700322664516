<template>
  <div class="container">
    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <section class="w-full">
        <div class="flex flex-wrap">
          <div class="bg-white shadow-box rounded lg:w-2/5 lg:mx-auto p-3 mb-4 md:mb-0 md:shadow-none w-full text-center">
            <img
              src="/geldrace/images/panel-inzicht.png"
              class="h-[50px] mx-auto mb-8"
            >

            <div class="text-2xl font-bold mb-4">
              {{ __(`mailing-survey.response.${statusTranslationKey}.title`) }}
            </div>

            <p
              v-for="(_, i) in $tm(`mailing-survey.response.${statusTranslationKey}.paragraphs`)"
              :key="i"
              class="mb-4"
            >
              <i18n-t
                scope="global"
                :keypath="`mailing-survey.response.${statusTranslationKey}.paragraphs.${i}`"
              >
                <template #survey_name>
                  {{ survey.name }}
                </template>
                <template #profile_link>
                  <Link
                    :href="route('frontend.profile-questions.index')"
                  >
                    {{ __('mailing-survey.response.profile-link') }}
                  </Link>
                </template>
                <template #faq_link>
                  <Link
                    :href="route('frontend.faq')"
                  >
                    {{ __('mailing-survey.response.faq-link') }}
                  </Link>
                </template>
              </i18n-t>
            </p>

            <p
              v-if="hasTooManyScreenOuts"
              class="mb-4"
            >
              {{ __('mailing-survey.response.screen_out.too_many_screen_outs', {count: maxScreenOuts}) }}
            </p>

            <Link
              :href="route('frontend.index')"
              class="block text-red underline hover:no-underline"
            >
              {{ __('mailing-survey.response.button') }}
            </Link>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import CleanLayout from '../../../Layout/Clean.vue';

export default {
  layout: CleanLayout,

  props: {
    status: {
      required: true,
      type: String,
    },

    statusTranslationKey: {
      required: true,
      type: String,
    },

    survey: {
      required: true,
      type: Object,
    },

    hasTooManyScreenOuts: {
      required: true,
      type: Boolean,
    },

    maxScreenOuts: {
      required: true,
      type: Number,
    },
  },
};
</script>

<template>
  <div class="container">
    <MobileBreadcrumbs
      :pages="[
        {name: __('news.index.title'), href: route('frontend.news.index')},
      ]"
      :current-page="news.title"
    />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="content md:pl-6 w-full md:w-3/4">
        <DesktopBreadcrumbs
          :pages="[
            {name: __('news.index.title'), href: route('frontend.news.index')},
          ]"
          :current-page="news.title"
        />

        <div class="flex flex-wrap">
          <div class="bg-white shadow-box rounded p-3 md:p-0 mb-4 md:shadow-none w-full">
            <img
              v-if="news.media"
              class="mb-2"
              :src="news.media.url"
              :alt="news.media.name"
              :title="news.media.name"
            >

            <h1 class="font-serif text-red mb-1 font-bold md:text-3xl">
              {{ news.title }}
            </h1>
            <p>
              <strong>
                {{ news.date }}
              </strong>
            </p>

            <!-- eslint-disable vue/no-v-html -->
            <div v-html="news.content" />
            <!-- eslint-enable -->

            <div v-if="news.is_puzzle_result">
              <p class="!mb-6">
                {{ __('news.show.puzzle_intro_text') }}
              </p>

              <div
                v-for="(puzzle, i) in news.puzzles"
                :key="`puzzle-result-${i}`"
                class="mb-6"
              >
                {{ __('news.show.puzzle_answer_text', {question: puzzle.question, answer: puzzle.correctAnswer.answer}) }}
              </div>

              {{ __('news.show.puzzle_outro_text') }}
            </div>
          </div>
        </div>
      </section>
    </div>

    <Link
      :href="route('frontend.news.index')"
      class="md:hidden mb-6 text-white md:text-red md:underline hover:no-underline"
    >
      <span class="material-symbols-outlined align-bottom text-white md:text-red font-bold">
        chevron_left
      </span>

      {{ __('news.show.back') }}
    </Link>
  </div>
</template>

<script>
export default {
  props: {
    news: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div class="container">
    <MobileBreadcrumbs :current-page="__('register.verification.title')" />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <section class="w-full">
        <div class="flex flex-wrap">
          <div class="bg-white shadow-box rounded p-3 mb-4 md:mb-0 md:shadow-none w-full">
            <DesktopBreadcrumbs :current-page="__('register.verification.title')" />

            <h1 class="font-serif text-red mb-2 font-bold md:text-3xl">
              {{ __('register.verification.title') }}
            </h1>

            <p>
              {{ __('register.verification.text', {email}) }}
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    email: {
      required: true,
      type: String,
    },
  },
};
</script>

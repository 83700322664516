<template>
  <GeldraceHead />

  <header class="py-4 md:py-0">
    <div class="container flex flex-wrap justify-between items-center">
      <BannerHeader />
    </div>
  </header>

  <slot />

  <GeldraceFoot />
</template>

<script>
import GeldraceHead from './Components/Head.vue';
import BannerHeader from './Components/BannerHeader.vue';
import GeldraceFoot from './Components/Foot.vue';

export default {
  components: {
    GeldraceHead,
    BannerHeader,
    GeldraceFoot,
  },
};
</script>

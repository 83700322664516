<template>
  <template v-if="!$isMobile">
    <div class="breadcrumbs bg-white text-xs mb-2">
      <ul class="list-inside">
        <li class="inline-block mr-1">
          <Link
            :href="route('frontend.index')"
            class="text-red underline hover:no-underline"
          >
            {{ __('tenant.name') }}
          </Link>
        </li>

        <li
          v-for="(page, i) in pages"
          :key="`breadcrumbs-${i}`"
          class="inline-block mr-1"
        >
          <span class="inline-block mr-1 text-black">
            &raquo;
          </span>

          <Link
            :href="page.href"
            class="text-red underline hover:no-underline"
          >
            {{ page.name }}
          </Link>
        </li>

        <li
          v-if="currentPage"
          class="inline-block mr-1"
        >
          <span class="inline-block mr-1 text-black">
            &raquo;
          </span>

          <span class="text-black">
            {{ currentPage }}
          </span>
        </li>
      </ul>
    </div>
  </template>
</template>

<script>
export default {
  props: {
    pages: {
      type: Array,
      required: false,
      default: null,
    },

    currentPage: {
      type: String,
      required: true,
    },
  },
};
</script>

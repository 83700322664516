<template>
  <div class="container">
    <MobileBreadcrumbs
      :pages="[
        {name: 'Webshops', href: route('frontend.webshop-category.index')},
      ]"
      :current-page="webshop.name"
    />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4 mb-6">
        <DesktopBreadcrumbs
          :pages="[
            {name: 'Webshops', href: route('frontend.webshop-category.index')},
          ]"
          :current-page="webshop.name"
        />

        <h1 class="text-white font-bold font-serif mb-4 text-center md:text-red md:text-3xl md:text-left">
          {{ webshop.name }}
        </h1>

        <div class="flex flex-wrap">
          <div class="w-full md:w-1/3">
            <div class="bg-white shadow-box rounded p-4 md:py-8 mb-6 md:mb-0">
              <img
                v-if="webshop.logo"
                class="mx-auto mb-4"
                :src="webshop.logo.url"
                :alt="webshop.logo.name"
                :title="webshop.logo.name"
              >

              <div class="text-center mb-4 flex items-center justify-center">
                <div class="flex mr-2">
                  <Stars
                    :full-stars="webshop.rating.fullStars"
                    :half-stars="webshop.rating.halfStars"
                    :empty-stars="webshop.rating.emptyStars"
                  />
                </div>

                <Link
                  :href="route('frontend.webshop-reviews.index', {webshop: webshop.slug})"
                  class="inline-block text-red underline hover:no-underline text-sm"
                >
                  {{ __('webshop.reviews', {count: webshop.rating.count}) }}
                </Link>
              </div>

              <img
                v-if="webshop.rectangle"
                class="mx-auto"
                :src="webshop.rectangle.url"
                :alt="webshop.rectangle.name"
                :title="webshop.rectangle.name"
              >

              <a
                v-if="$page.props.auth.member && canHaveCashback"
                :href="route('frontend.clickout.webshop', {webshop: webshop.slug, origin: 'website'})"
                target="_blank"
                class="btn mt-3 text-sm md:text-base"
              >
                {{ __(`advertiser.button.${webshop.buttonType}`) }}

                <span class="material-symbols-outlined text-white font-bold">
                  chevron_right
                </span>
              </a>
              <button
                v-else-if="$page.props.auth.member && !canHaveCashback"
                type="button"
                class="btn mt-3 text-sm md:text-base"
                @click="$parent.$refs.alreadyParticipated.openModal(route('frontend.clickout.webshop', {webshop: webshop.slug, origin: 'website'}))"
              >
                {{ __(`advertiser.button.${webshop.buttonType}`) }}

                <span class="material-symbols-outlined text-white font-bold">
                  chevron_right
                </span>
              </button>
              <button
                v-else
                type="button"
                class="btn mt-3 text-sm md:text-base"
                @click="$parent.$refs.loginModal.doOpenModal()"
              >
                {{ __(`advertiser.button.${webshop.buttonType}`) }}

                <span class="material-symbols-outlined text-white font-bold">
                  chevron_right
                </span>
              </button>
            </div>
          </div>

          <div class="w-full md:w-2/3 md:pl-8">
            <CommissionModels
              :webshop="webshop"
              :commission-models="commission.models"
            />

            <div
              v-if="hasTerms"
              class="mt-6 md:mt-0"
            >
              <h2 class="text-white font-bold font-serif mb-4 text-center md:text-red md:text-left md:mb-2">
                {{ __('webshop.terms.title') }}
              </h2>

              <div class="bg-white shadow-box rounded p-3 md:p-0 mb-4 md:shadow-none w-full">
                <div v-if="terms['General']">
                  <h3 class="font-bold mb-4">
                    {{ __cashback(webshop.usePoints, 'webshop.conditions') }}
                  </h3>

                  <ul class="list-inside list-disc px-4 mb-4 space-y-2">
                    <li
                      v-for="term in terms['General']"
                      :key="term.id"
                    >
                      {{ term.description }}
                    </li>
                  </ul>
                </div>

                <div v-if="terms['No cashback']">
                  <h3 class="font-bold mb-4">
                    {{ __cashback(webshop.usePoints, 'webshop.no-cashback-information') }}
                  </h3>

                  <ul class="list-inside list-disc px-4 mb-4 space-y-2">
                    <li
                      v-for="term in terms['No cashback']"
                      :key="term.id"
                    >
                      {{ term.description }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import CommissionModels from './Components/CommissionModels.vue';
import Stars from '../../Components/Stars.vue';

export default {
  components: {
    CommissionModels,
    Stars,
  },

  props: {
    webshop: {
      type: Object,
      required: true,
    },

    commission: {
      type: Object,
      required: true,
    },

    terms: {
      type: Object,
      required: true,
    },

    canHaveCashback: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    hasTerms() {
      return ('General' in this.terms && this.terms['General'].length > 0)
        || ('No cashback' in this.terms && this.terms['No cashback'].length > 0);
    },
  },
};
</script>

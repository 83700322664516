<template>
  <div>
    <slot
      name="modal-button"
      :openModal="openModal"
    />

    <HeadlessTransitionRoot
      appear
      :show="isOpen"
      as="div"
    >
      <HeadlessDialog
        as="div"
        @close="closeModal"
      >
        <div class="fixed inset-0 z-50 overflow-y-auto">
          <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-60 text-center md:block md:p-0">
            <HeadlessTransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <HeadlessDialogOverlay class="fixed inset-0 bg-red bg-opacity-80 transition-opacity" />
            </HeadlessTransitionChild>

            <HeadlessTransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="w-screen md:w-auto inline-block md:mt-36 align-bottom text-left overflow-hidden shadow transform transition-all md:align-middle md:max-w-lg"
              >
                <div class="d-border m-1 bg-yellow">
                  <div class="flex justify-between items-center p-3 md:p-2 font-bold">
                    <div class="bg-yellow text-black font-serif">
                      {{ title }}
                    </div>

                    <button
                      class="cursor-pointer leading-[0]"
                      @click="closeModal"
                    >
                      <span class="material-symbols-outlined text-black">
                        cancel
                      </span>
                    </button>
                  </div>

                  <div class="p-3 bg-white">
                    <slot
                      name="modal-content"
                      :closeModal="closeModal"
                    />
                  </div>
                </div>
              </div>
            </HeadlessTransitionChild>
          </div>
        </div>
      </HeadlessDialog>
    </HeadlessTransitionRoot>
  </div>
</template>

<script>
import {ref} from 'vue';
import {Dialog as HeadlessDialog, DialogOverlay as HeadlessDialogOverlay, TransitionChild as HeadlessTransitionChild, TransitionRoot as HeadlessTransitionRoot} from '@headlessui/vue';

export default {
  components: {
    HeadlessTransitionRoot,
    HeadlessTransitionChild,
    HeadlessDialog,
    HeadlessDialogOverlay,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    open: {
      type: Boolean,
      required: false,
    },
  },

  setup(props) {
    const isOpen = ref(props.open);

    return {
      isOpen,

      closeModal() {
        isOpen.value = false;
      },

      openModal() {
        isOpen.value = true;
      },
    };
  },
};
</script>

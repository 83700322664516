<template>
  <div class="container">
    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <section class="w-full">
        <div class="flex flex-wrap">
          <div class="bg-white shadow-box rounded lg:w-2/5 lg:mx-auto p-3 mb-4 md:mb-0 md:shadow-none w-full text-center">
            <div v-show="isFirstQuestion">
              <div class="mb-6 text-left">
                {{ __('mailing-survey.index.introduction-greeting', {name: member.first_name}) }}<br>
                <br>
                {{ __('mailing-survey.index.introduction-text') }}
              </div>
            </div>

            <ProfileQuestionsFactory
              v-model="form.questions[currentQuestion.id]"
              :question="currentQuestion"
              title-classes="text-2xl mb-4"
              @validation-error="validationError(currentQuestion.id, $event)"
            />

            <div
              v-show="validationErrorMessages[currentQuestion.id]"
              class="mt-2 text-red text-sm text-left"
            >
              *{{ validationErrorMessages[currentQuestion.id] }}
            </div>

            <div class="flex items-center justify-between mt-4">
              <div class="h-fit">
                <button
                  v-show="!isFirstQuestion"
                  type="button"
                  class="text-xs text-red hover:underline"
                  @click="previousStep"
                >
                  {{ __('mailing-survey.index.previous-question-button') }}
                </button>
              </div>

              <button
                type="button"
                class="btn text-sm md:text-base mt-4 !w-[auto] !px-2"
                :disabled="updating.includes(currentQuestion.id) || validationErrorMessages[currentQuestion.id]"
                @click="updateQuestion(currentQuestion.id, form.questions[currentQuestion.id])"
              >
                <span v-show="!isFinalQuestion">
                  {{ __('mailing-survey.index.next-question-button') }}
                </span>
                <span v-show="isFinalQuestion">
                  {{ __('mailing-survey.index.continue-to-survey-button') }}
                </span>
                <span
                  v-if="updating.includes(currentQuestion.id)"
                  class="material-symbols-outlined text-white animate-spin font-bold"
                >
                  autorenew
                </span>
                <span
                  v-else
                  class="material-symbols-outlined text-white font-bold"
                >
                  chevron_right
                </span>
              </button>
            </div>
          </div>
        </div>

        <div class="w-full lg:w-2/5 lg:mx-auto bg-gray-200 border border-white">
          <div
            class="bg-red text-2xs text-center py-1.5 leading-none"
            :class="progressPercentage > 0 ? 'text-white' : 'text-gray-900'"
            :style="`width: ${progressPercentage}%`"
          >
            {{ Math.floor(progressPercentage) }}%
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {useForm} from '@inertiajs/vue3';
import CleanLayout from '../../../Layout/Clean.vue';
import ProfileQuestionsFactory from '../../../Components/ProfileQuestions/Factory.vue';

export default {
  components: {
    ProfileQuestionsFactory,
  },

  layout: CleanLayout,

  props: {
    clickout: {
      required: true,
      type: String,
    },

    member: {
      required: true,
      type: Object,
    },

    questions: {
      required: true,
      type: Array,
    },
  },

  setup(props) {
    const questions = props.questions.reduce((accumulator, question) => {
      const memberAnswers = question.memberAnswers.map((memberAnswer) => memberAnswer.profile_answer_id);
      let answers = question.answers.filter((answer) => memberAnswers.includes(answer.id)).map((answer) => answer.id);
      accumulator[question.id] = answers || null;
      return accumulator;
    }, {});

    const form = useForm({questions});

    return {
      form,
    };
  },

  data() {
    return {
      currentStep: 0,
      updating: [],
      validationErrorMessages: {},
    };
  },

  computed: {
    isFirstQuestion() {
      return this.currentStep === 0;
    },

    isFinalQuestion() {
      return this.currentStep === (this.questions.length - 1);
    },

    currentQuestion() {
      return this.questions[this.currentStep];
    },

    progressPercentage() {
      if (this.currentStep === 0) {
        return 0;
      }

      const percentage = (this.currentStep / this.questions.length) * 100;
      return percentage > 100 ? 100 : percentage;
    },
  },

  methods: {
    validationError(questionId, message) {
      this.validationErrorMessages[questionId] = message;
    },

    updateQuestion(questionId, answers) {
      this.updating.push(questionId);

      axios.post(this.route('frontend.api.profile-questions.update-question-answer', {profileQuestion: questionId}), {
        answers: answers,
      })
        .then(() => this.nextStep(questionId));
    },

    previousStep() {
      if (this.isFirstQuestion) {
        return;
      }

      this.currentStep--;
    },

    nextStep(questionId) {
      if (this.isFinalQuestion) {
        return this.$inertia.post(this.route('frontend.mailing.survey.store', [this.clickout]));
      }

      this.currentStep++;
      this.updating.splice(this.updating.indexOf(questionId), 1);
    },
  },
};
</script>

<template>
  <div class="container">
    <MobileBreadcrumbs :current-page="__('tell-a-friend.index.title')" />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4 mb-6">
        <DesktopBreadcrumbs :current-page="__('tell-a-friend.index.title')" />

        <div class="mb-6 w-full">
          <h1 class="text-white font-bold font-serif mb-4 text-center md:text-red md:text-3xl md:text-left">
            {{ __('tell-a-friend.index.title') }}
          </h1>

          <div class="flex flex-wrap text-white mt-4 mb-2 md:text-black text-xs md:text-sm">
            <span class="w-5/12 md:w-8/12 font-bold pl-3 md:pl-0">
              {{ __('tell-a-friend.index.table.username-column') }}
            </span>
            <span class="w-3/12 md:w-2/12 font-bold pl-3">
              {{ __('tell-a-friend.index.table.date-column') }}
            </span>
            <span class="w-4/12 md:w-2/12 font-bold pl-3">
              {{ __('tell-a-friend.index.table.status-column') }}
            </span>
          </div>

          <div class="bg-white rounded md:rounded-none divide-solid divide-y divide-yellow-500 mb-4 md:border-b md:border-t md:border-yellow-500">
            <template
              v-for="(referredMember, i) in referredMembers.data"
              :key="`referred-member-${i}`"
            >
              <div class="flex flex-wrap py-2 items-center">
                <span class="pl-3 w-5/12 md:w-8/12 leading-5 md:pl-0">
                  {{ referredMember.username }}
                </span>
                <span class="w-3/12 md:w-2/12 text-xs md:text-base pl-3">
                  {{ referredMember.registration_date }}
                </span>
                <span class="w-4/12 md:w-2/12 text-xs md:text-base pl-3">
                  {{ __(`tell-a-friend.status.${referredMember.status}`) }}
                </span>
              </div>
            </template>
          </div>

          <div
            v-if="needsPagination"
            class="text-sm w-full p-3"
          >
            <div
              class="border-gray-200 border flex flex-wrap justify-center divide-solid divide-x divide-gray-200 items-center bg-white md:w-3/6 md:mx-auto"
            >
              <template
                v-for="(link, i) in referredMembers.meta.pagination.links"
                :key="`referred-members-page-link-${i}`"
              >
                <Link
                  v-if="link.label === __('pagination.previous')"
                  :href="(link.url ?? referredMembers.meta.pagination.first_page_url)"
                  class="w-1/12 text-center py-2"
                  :class="{ 'font-bold': link.active }"
                  :only="['referredMembers']"
                  preserve-scroll
                >
                  <span class="material-symbols-outlined align-middle">
                    chevron_left
                  </span>
                </Link>
                <Link
                  v-else-if="link.label === __('pagination.next')"
                  :href="(link.url ?? referredMembers.meta.pagination.last_page_url)"
                  class="w-1/12 text-center py-2"
                  :class="{ 'font-bold': link.active }"
                  :only="['referredMembers']"
                  preserve-scroll
                >
                  <span class="material-symbols-outlined align-middle">
                    chevron_right
                  </span>
                </Link>
                <Link
                  v-else
                  :href="link.url"
                  class="w-1/12 text-center py-2"
                  :class="{ 'font-bold': link.active }"
                  :only="['referredMembers']"
                  preserve-scroll
                >
                  {{ link.label }}
                </Link>
              </template>
            </div>
          </div>
        </div>

        <div class="bg-white shadow-box rounded p-3 md:p-0 mb-4 md:shadow-none w-full">
          <h1 class="md:hidden font-serif text-red mb-2 font-bold">
            {{ __('tell-a-friend.index.title') }}
          </h1>

          <p class="text-sm md:text-base mb-4">
            {{ __('tell-a-friend.index.text', {bonus: bonus}) }}

            <i18n-t
              keypath="tell-a-friend.index.conditions.text"
              scope="global"
            >
              <template #link>
                <Link
                  :href="route('frontend.tell-a-friend.conditions')"
                  class="text-red hover:underline"
                >
                  {{ __('tell-a-friend.index.conditions.link-text') }}
                </Link>
              </template>
            </i18n-t>
          </p>

          <p class="text-sm md:text-base">
            {{ __('tell-a-friend.index.share-url') }}

            <a
              :href="shareUrl"
              target="_blank"
              class="block text-red underline hover:no-underline"
            >
              {{ shareUrl }}
            </a>
          </p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    referredMembers: {
      type: Object,
      required: true,
    },

    shareUrl: {
      type: String,
      required: true,
    },

    bonus: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      needsPagination: this.referredMembers.meta?.pagination.links.length > 3,
    };
  },
};
</script>

<template>
  <div class="webshop text-center bg-white p-2 md:p-4 shadow-box rounded flex flex-wrap items-center">
    <Link
      :href="route('frontend.clickout.click-action', clickAction.id)"
      class="block mb-1"
    >
      <img
        v-if="clickAction.logo"
        :src="clickAction.logo.url"
        :alt="clickAction.name"
        :title="clickAction.name"
      >
      <span v-else>
        {{ clickAction.name }}
      </span>
    </Link>

    <span class="block text-sm w-full mb-3">
      {{ clickAction.description }}
    </span>

    <span class="block text-sm text-red font-bold w-full">
      {{ clickAction.reward }}
    </span>

    <a
      :href="route('frontend.clickout.click-action', clickAction.id)"
      target="_blank"
      class="btn my-3 text-xs md:text-sm"
    >
      {{ __('click-action.block.button') }}

      <span class="material-symbols-outlined text-white font-bold">
        chevron_right
      </span>
    </a>

    <div class="w-full font-bold text-red text-center">
      {{ __('click-action.block.member-clicked') }}
      <template v-if="clickAction.has_clicks">
        {{ __('click-action.block.member-clicked-yes') }}
      </template>
      <template v-else>
        {{ __('click-action.block.member-clicked-no') }}
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    clickAction: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div class="block md:hidden">
    <Link
      :href="route('frontend.index')"
      class="block"
    >
      <img src="/geldrace/images/logo.svg">
    </Link>
  </div>

  <div class="w-full pt-4 md:pt-0 md:relative">
    <img
      src="/geldrace/images/header.svg"
      class="rounded md:rounded-none w-full "
    >

    <Link
      :href="route('frontend.index')"
      class="hidden md:block absolute left-4 top-4"
    >
      <img src="/geldrace/images/logo.svg">
    </Link>
  </div>
</template>

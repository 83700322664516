<template>
  <Modal
    ref="modal"
    :title="__('general.are-you-sure')"
  >
    <template #modal-button="{ openModal }">
      <button
        class="absolute top-0 right-0"
        @click="openModal"
      >
        <span class="material-symbols-outlined text-red font-bold">
          close
        </span>
      </button>
    </template>

    <template #modal-content="{ closeModal }">
      <p class="mb-4">
        {{ __('account.payout.bank-account.confirm-delete') }}
      </p>

      <div class="flex justify-end">
        <button
          type="button"
          class="btn text-sm md:!w-[auto] mr-3 !px-2"
          @click="closeModal"
        >
          Annuleren
        </button>
        <Link
          method="post"
          :data="{id: bankAccountId}"
          :href="route('frontend.account.payout.bank.destroy')"
          class="btn text-sm md:!w-[auto] !px-2"
          as="button"
        >
          Verwijder
        </Link>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '../../../../Components/Modal.vue';

export default {
  components: {
    Modal,
  },

  props: {
    bankAccountId: {
      type: Number,
      required: true,
    },
  },
};
</script>

<template>
  <div class="container">
    <MobileBreadcrumbs :current-page="__('games.index.title')" />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4">
        <DesktopBreadcrumbs :current-page="__('games.index.title')" />

        <h1 class="text-white font-bold font-serif mb-4 text-center md:text-red md:text-3xl md:text-left">
          {{ __('games.index.title') }}
        </h1>

        <div class="grid grid-cols-2 lg:grid-cols-5 gap-3 mb-8">
          <template
            v-for="game in games"
            :key="`game-${game.id}`"
          >
            <Link
              :href="route('frontend.games.show', game.slug)"
              class="shadow-box rounded px-2 pt-8 pb-4 flex flex-wrap content-between bg-white"
            >
              <div class="mx-auto mb-4 text-center">
                <span class="block w-full">
                  <img
                    class="mx-auto mb-4"
                    :src="`/geldrace/images/games/${game.slug}.png`"
                    :alt="game.name"
                  >
                </span>
                <span class="font-bold block w-full">
                  {{ game.name }}
                </span>
              </div>

              <div class="btn md:!w-[auto] text-sm md:text-base md:inline-flex md:px-4 mx-auto">
                {{ __('games.index.button') }}

                <span class="material-symbols-outlined text-white font-bold">
                  chevron_right
                </span>
              </div>
            </Link>
          </template>
        </div>

        <WebshopSuggestions :webshop-suggestions="webshopSuggestions" />
      </section>
    </div>
  </div>
</template>

<script>
import WebshopSuggestions from '../../Components/WebshopSuggestions.vue';

export default {
  components: {WebshopSuggestions},
  props: {
    games: {
      type: Array,
      required: true,
    },

    webshopSuggestions: {
      type: Array,
      required: true,
    },
  },
};
</script>

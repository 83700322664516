<template>
  <div class="container">
    <MobileBreadcrumbs
      :pages="[
        {name: __('account.overview.title'), href: route('frontend.account')},
      ]"
      :current-page="__('account.profile.title')"
    />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4 mb-6">
        <DesktopBreadcrumbs
          :pages="[
            {name: __('account.overview.title'), href: route('frontend.account')},
          ]"
          :current-page="__('account.profile.title')"
        />

        <div class="bg-white shadow-box rounded p-3 md:p-0 mb-4 md:shadow-none w-full">
          <h1 class="font-serif text-red mb-2 font-bold md:text-3xl">
            {{ __('account.profile.title') }}
          </h1>

          <form
            class="form mt-4"
            @submit.prevent="form.post(route('frontend.account.profile.store'))"
          >
            <div class="flex flex-wrap mb-3 md:items-center">
              <label class="w-full text-sm block mb-1 md:mb-0 md:w-1/6 md:font-bold">
                {{ __('account.profile.profile-picture') }}:
              </label>
              <div class="w-full md:w-5/6 lg:w-2/4 flex flex-wrap">
                <div class="w-1/3 md:w-1/6 flex items-center justify-center">
                  <img
                    v-if="member.picture"
                    :src="member.picture"
                  >
                  <span
                    v-else
                    class="material-symbols-outlined text-4xl"
                  >
                    person
                  </span>
                </div>
                <div class="w-2/3 pl-4 flex items-center flex-wrap md:w-5/6">
                  <div class="text-sm">
                    <Link
                      :href="route('frontend.account.picture')"
                      class="block text-red underline hover:no-underline w-full mb-2"
                    >
                      {{ __('account.profile.profile-picture-edit') }}
                    </Link>

                    <Modal
                      v-if="member.picture"
                      :title="__('general.are-you-sure')"
                    >
                      <template #modal-button="scope">
                        <div
                          class="block text-red underline hover:no-underline w-full cursor-pointer"
                          @click="scope.openModal()"
                        >
                          {{ __('account.profile.profile-picture-delete') }}
                        </div>
                      </template>
                      <template #modal-content="scope">
                        <p class="mb-4">
                          {{ __('account.profile.profile-picture-delete-confirm') }}
                        </p>

                        <div class="flex justify-end">
                          <button
                            type="button"
                            class="btn text-sm md:!w-[auto] mr-3 !px-2"
                            @click="scope.closeModal"
                          >
                            {{ __('account.profile.profile-picture-delete-confirm-no') }}
                          </button>
                          <Link
                            :href="route('frontend.account.picture.delete')"
                            as="button"
                            method="delete"
                            class="btn text-sm md:!w-[auto] !px-2"
                          >
                            {{ __('account.profile.profile-picture-delete-confirm-yes') }}
                          </Link>
                        </div>
                      </template>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex flex-wrap mb-3 md:items-center">
              <label class="w-full text-sm block mb-1 md:mb-0 md:w-1/6 md:font-bold">
                {{ __('general.username') }}:
              </label>
              <div class="w-full md:w-5/6 lg:w-2/4">
                <input
                  type="text"
                  class="placeholder-black"
                  :placeholder="member.username"
                  disabled
                >
              </div>
            </div>

            <div class="flex flex-wrap mb-3 md:items-center">
              <label class="w-full text-sm block mb-1 md:mb-0 md:w-1/6 md:font-bold">
                {{ __('general.gender') }}:
              </label>
              <div class="w-full md:w-5/6 lg:w-2/4">
                <select
                  v-model="form.gender"
                  required
                >
                  <option
                    disabled
                    value=""
                  >
                    {{ __('general.gender-placeholder') }}
                  </option>
                  <option
                    v-for="(gender, i) in genders"
                    :key="`gender-${i}`"
                    :value="gender.id"
                  >
                    {{ gender.name }}
                  </option>
                </select>
              </div>
              <div
                v-if="form.errors.gender"
                class="w-full text-red text-xs italic"
              >
                <div class="md:w-1/6 inline-block" />
                <p class="inline-block">
                  {{ form.errors.gender }}
                </p>
              </div>
            </div>

            <div class="flex flex-wrap mb-3 md:items-center">
              <label class="w-full text-sm block mb-1 md:mb-0 md:w-1/6 md:font-bold">
                {{ __('general.first_name') }}:
              </label>
              <div class="w-full md:w-5/6 lg:w-2/4">
                <input
                  v-model="form.first_name"
                  type="text"
                  name="first_name"
                  autocomplete="given-name"
                  required
                >
              </div>
              <div
                v-if="form.errors.first_name"
                class="w-full text-red text-xs italic"
              >
                <div class="md:w-1/6 inline-block" />
                <p class="inline-block">
                  {{ form.errors.first_name }}
                </p>
              </div>
            </div>

            <div class="flex flex-wrap mb-3 md:items-center">
              <label class="w-full text-sm block mb-1 md:mb-0 md:w-1/6 md:font-bold">
                {{ __('general.emailaddress') }}:
              </label>
              <div class="w-full md:w-5/6 lg:w-2/4">
                <input
                  type="email"
                  name="email"
                  autocomplete="email"
                  :placeholder="member.email"
                  class="placeholder-black"
                  disabled
                >
              </div>
              <div class="w-full text-red text-sm mt-1">
                <div class="md:w-1/6 inline-block" />
                <Link
                  class="inline-block underline hover:no-underline"
                  :href="route('frontend.account.change-email')"
                >
                  {{ __('account.profile.change_email') }}
                </Link>
              </div>
            </div>

            <div class="flex flex-wrap mb-3 md:items-center">
              <BirthdateField
                v-model:day="form.birth_date_day"
                v-model:month="form.birth_date_month"
                v-model:year="form.birth_date_year"
                v-model:parental-approval="form.parental_approval"
                :day-options="days"
                :month-options="months"
                :year-options="years"
                :minimum-age="minimumAge"
                :errors="form.errors"
                :inline="true"
                :disabled="!member.can_set_birth_date"
              />
            </div>

            <div class="flex flex-wrap mb-3 md:items-center">
              <label class="w-full text-sm block mb-1 md:mb-0 md:w-1/6 md:font-bold">
                {{ __('general.zipcode') }}:
              </label>
              <div class="w-full md:w-5/6 lg:w-2/4">
                <input
                  v-model="form.zipcode"
                  type="text"
                  name="zipcode"
                  autocomplete="postal-code"
                >
              </div>
              <div
                v-if="form.errors.zipcode"
                class="w-full text-red text-xs italic"
              >
                <div class="md:w-1/6 inline-block" />
                <p class="inline-block">
                  {{ form.errors.zipcode }}
                </p>
              </div>
            </div>

            <div class="flex flex-wrap mb-3 md:items-center">
              <label class="w-full text-sm block mb-1 md:mb-0 md:w-1/6 md:font-bold">
                {{ __('general.education') }}:
              </label>
              <div class="w-full md:w-5/6 lg:w-2/4">
                <select v-model="form.education">
                  <option
                    disabled
                    :value="null"
                  >
                    {{ __('general.education-placeholder') }}
                  </option>
                  <option
                    v-for="(education, i) in educationLevels"
                    :key="`education-${i}`"
                    :value="education"
                  >
                    {{ education }}
                  </option>
                </select>
              </div>
              <div
                v-if="form.errors.education"
                class="w-full text-red text-xs italic"
              >
                <div class="md:w-1/6 inline-block" />
                <p class="inline-block">
                  {{ form.errors.education }}
                </p>
              </div>
            </div>

            <div class="flex flex-wrap md:items-center">
              <label class="hidden md:block md:w-1/6 md:font-bold" />
              <div class="w-full md:w-5/6 lg:w-2/4">
                <button
                  type="submit"
                  class="btn text-sm md:text-base"
                  :disabled="form.processing"
                >
                  {{ __('account.profile.button') }}
                  <span class="material-symbols-outlined text-white font-bold">
                    chevron_right
                  </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import BirthdateField from '../../../Components/Form/BirthdateField.vue';
import Modal from '../../../Components/Modal.vue';

export default {
  components: {
    BirthdateField,
    Modal,
  },

  props: {
    member: {
      type: Object,
      required: true,
    },

    days: {
      type: Object,
      required: true,
    },

    months: {
      type: Object,
      required: true,
    },

    years: {
      type: Object,
      required: true,
    },

    genders: {
      type: Array,
      required: true,
    },

    minimumAge: {
      type: Number,
      required: true,
    },

    educationLevels: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    const form = useForm({
      language_id: props.member.language_id,
      timezone: props.member.timezone,
      first_name: props.member.first_name,
      last_name: props.member.last_name,
      gender: props.member.gender ?? '',
      birth_date_year: props.member.birth_date_year?.toString() ?? '',
      birth_date_month: props.member.birth_date_month?.toString() ?? '',
      birth_date_day: props.member.birth_date_day?.toString() ?? '',
      zipcode: props.member.zipcode,
      education: props.member.education,
    });

    return {
      form,
    };
  },
};
</script>

<template>
  <div class="container">
    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <section class="w-full">
        <div class="flex flex-wrap">
          <div class="bg-white shadow-box rounded p-3 mb-4 md:mb-0 md:shadow-none w-full text-center">
            <p class="mb-4 mx-auto max-w-xl">
              Hi Geldracer,
            </p>
            <p class="mb-4 mx-auto max-w-xl">
              We zien dat je op een oude mail van Geldrace hebt geklikt. Deze mail is verstuurd vanaf het oude platform.
            </p>
            <p class="mb-4 mx-auto max-w-xl">
              Sinds {{ liveDate }} is Geldrace in een nieuw jasje gestoken.
            </p>
            <p class="mb-4 mx-auto max-w-xl">
              Neem snel een kijkje op
              <Link
                :href="route('frontend.index')"
                class="text-red underline hover:no-underline"
              >
                {{ route('frontend.index') }}
              </Link>
              voor de nieuwste aanbiedingen, puzzels, nieuwsberichten en meer.
            </p>
            <p class="mb-4 mx-auto max-w-xl">
              Veel spaar plezier!
            </p>
            <p class="mb-4 mx-auto max-w-xl">
              Team Geldrace
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    liveDate: {
      type: String,
      required: true,
    },
  },
};
</script>

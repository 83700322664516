<template>
  <div class="container">
    <MobileBreadcrumbs :current-page="__('passwords.reset-page.title')" />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <section class="w-full">
        <div class="flex flex-wrap">
          <div class="bg-white shadow-box rounded p-3 mb-4 md:mb-0 md:shadow-none w-full">
            <DesktopBreadcrumbs :current-page="__('passwords.reset-page.title')" />

            <h1 class="font-serif text-red mb-2 font-bold md:text-3xl">
              {{ __('passwords.reset-page.title') }}
            </h1>

            <p v-if="status">
              {{ status }}
              <br>
              <Link
                :href="route('frontend.index')"
                class="text-red underline hover:no-underline"
              >
                {{ __('passwords.reset-page.back-link') }}
              </Link>
            </p>
            <div v-else>
              <p>
                {{ __('passwords.reset-page.text') }}
              </p>

              <form
                class="form md:w-2/6 mt-4"
                @submit.prevent="form.post(route('frontend.password-reset.store'))"
              >
                <div
                  v-if="form.errors.email"
                  class="text-red"
                >
                  {{ form.errors.email }}
                </div>

                <div class="flex flex-wrap mb-3 md:items-center">
                  <label class="w-full text-sm block mb-1 md:font-bold">
                    Wachtwoord:
                  </label>

                  <Password v-model="form.password" />

                  <div
                    v-if="form.errors.password"
                    class="text-red text-xs italic mt-2"
                  >
                    <p class="mb-1">
                      {{ __('register.errors.password_requirements') }}
                    </p>
                    <ul class="list-disc space-y-1 ml-4">
                      <li
                        v-for="error in form.errors.password.split('|')"
                        :key="error"
                      >
                        {{ error }}
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="flex flex-wrap md:items-center">
                  <div class="w-full">
                    <button
                      type="submit"
                      class="btn text-sm md:text-base"
                      :disabled="form.processing"
                    >
                      {{ __('passwords.reset-page.button') }}

                      <span class="material-symbols-outlined text-white font-bold">
                        chevron_right
                      </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import Password from '../../Components/Form/Password.vue';

export default {
  components: {
    Password,
  },

  props: {
    token: {
      type: String,
      required: true,
    },

    email: {
      type: String,
      required: true,
    },

    status: {
      type: String,
      required: false,
      default: null,
    },
  },

  setup(props) {
    const form = useForm({
      token: props.token,
      email: props.email,
      password: '',
    });

    return {form};
  },
};
</script>

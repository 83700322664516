<template>
  <template v-if="blockType === 'billboard_only'">
    <button
      class="no-underline w-full"
      @click="goToWebshop()"
    >
      <div
        v-if="webshop.billboard"
        class="aspect-[970/250] bg-cover w-full bg-origin-padding bg-center"
        :style="`background-image: url(${webshop.billboard.url})`"
      />
      <div
        v-else
        class="block w-full text-base md:text-xl text-center truncate"
      >
        {{ webshop.name }}
      </div>
    </button>
  </template>

  <template v-else>
    <div class="webshop text-center bg-white p-2 md:p-4 shadow-box rounded flex flex-wrap items-center">
      <button
        class="block mb-3 w-full"
        @click="goToWebshop()"
      >
        <img
          v-if="webshop.logo"
          :src="webshop.logo.url"
          :alt="webshop.name"
          :title="webshop.name"
        >
        <p
          v-else
          class="truncate"
        >
          {{ webshop.name }}
        </p>
      </button>

      <span class="block text-sm text-red font-bold w-full">
        {{ __cashback(webshop.usePoints, webshop.maxCashback.hasMultipleCashbacks ? 'cashback.max.multiple' : 'cashback.max.single', {value: webshop.maxCashback.maxCashback}) }}
      </span>
      <span
        v-if="webshop.maxCashback.maxCashbackIsPercentage"
        class="block text-sm w-full"
      >
        {{ __('webshop.block.requirements') }}
      </span>

      <button
        class="btn mt-3 text-xs md:text-sm"
        @click="goToWebshop()"
      >
        {{ __(`advertiser.button.${webshop.buttonType}`) }}

        <span class="material-symbols-outlined text-white font-bold">
          chevron_right
        </span>
      </button>

      <div class="flex justify-center items-center mt-2 w-full">
        <Link
          :href="route('frontend.webshop.show', webshop.slug)"
          class="block text-sm text-red hover:underline"
        >
          {{ __('webshop.block.details') }}
        </Link>
      </div>
    </div>
  </template>
</template>

<script>
export default {
  props: {
    webshop: {
      type: Object,
      required: true,
    },

    blockType: {
      type: String,
      required: false,
      default: 'logo',
      validator(type) {
        return ['logo', 'billboard_only'].includes(type);
      },
    },
  },

  methods: {
    goToWebshop() {
      if (this.$page.props.auth.member) {
        window.open(this.route('frontend.clickout.webshop', {webshop: this.webshop.slug, origin: 'website'}), '_blank');
        return;
      }

      this.$parent.$parent.$refs.loginModal.doOpenModal();
    },
  },
};
</script>

<template>
  <div class="container">
    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <section class="w-full">
        <div class="flex flex-wrap">
          <div class="bg-white shadow-box rounded p-3 mb-4 md:mb-0 md:shadow-none w-full text-center">
            <span class="material-symbols-outlined text-6xl text-red mb-4">cancel</span>

            <h1 class="font-serif text-red mb-2 font-bold md:text-3xl">
              {{ __('error.' + errorType + '.title') }}
            </h1>

            <p class="mb-4 mx-auto max-w-xl">
              {{ __('error.' + errorType + '.error') }}
            </p>

            <Link
              :href="route('frontend.index')"
              class="block text-red underline hover:no-underline"
            >
              {{ __('error.' + errorType + '.button') }}
            </Link>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import CleanLayout from '../../Layout/Clean.vue';

export default {
  layout: CleanLayout,

  props: {
    errorType: {
      type: String,
      required: true,
    },
  },
};
</script>

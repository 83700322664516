<template>
  <form
    action="."
    @submit.prevent="showWebshopOrSearchPage(null)"
  >
    <Combobox
      v-model="selectedWebshop"
      as="div"
      name="q"
      class="relative w-full"
      :class="{ 'lg:w-4/6': !alternativeStyle }"
      @update:modelValue="showWebshopOrSearchPage"
    >
      <div :class="{ 'md:white md:shadow-box md:p-4 rounded-md': !alternativeStyle }">
        <div
          class="border-yellow rounded bg-white flex"
          :class="alternativeStyle ? 'border-3 rounded-xl md:border md:rounded' : 'border-3 rounded-xl'"
        >
          <ComboboxInput
            class="peer border-none appearance-none focus:border-opacity-0 focus:ring-0 text-black font-serif w-full"
            :class="alternativeStyle ? 'rounded-tl-xl md:rounded-tl rounded-bl-xl md:rounded-bl py-3 md:py-1.5 md:text-sm' : 'rounded-tl-xl rounded-bl-xl py-3'"
            :placeholder="inputPlaceholder"
            :value="searchQuery"
            type="text"
            @change="searchQuery = $event.target.value"
          />
          <button
            class="flex items-center peer-placeholder-shown:hidden"
            @click="clear"
          >
            <span class="material-symbols-outlined">
              close
            </span>
          </button>

          <button
            type="submit"
            class="flex justify-end items-center text-red ml-2"
          >
            <span
              v-show="!isSearching"
              class="material-symbols-outlined mr-3 font-bold"
            >
              search
            </span>
            <span
              v-show="isSearching"
              class="material-symbols-outlined mr-3 font-bold animate-spin"
            >
              autorenew
            </span>
          </button>
        </div>
      </div>

      <ComboboxOptions
        as="div"
        class="absolute z-10 bg-white rounded-lg shadow-box w-full px-4 py-2 -mt-2 sidebar"
        :class="alternativeStyle ? 'top-16 md:top-12' : 'top-16 md:top-20'"
      >
        <ul class="divide-solid divide-y divide-yellow-500">
          <ComboboxOption
            v-if="searchQuery && searchQuery.length >= minimumSearchLength"
            v-slot="{ active, selected }"
            :value="null"
          >
            <li
              class="py-1 relative hover:underline"
              :class="{ 'text-red': active || selected }"
            >
              <Link
                :href="route('frontend.webshop.search', {query: searchQuery})"
                class="block"
                @click="$emit('navigate')"
              >
                {{ __('webshop-search.search-for', {search: searchQuery}) }}
              </Link>
            </li>
          </ComboboxOption>
          <ComboboxOption
            v-for="(webshop, i) in boosterWebshops"
            :key="`booster-webshop-search-${i}`"
            v-slot="{ active, selected }"
            :value="route('frontend.webshop.show', webshop.slug)"
            as="template"
          >
            <li
              class="py-1 relative hover:underline"
              :class="{ 'text-red': active || selected }"
            >
              <div class="block cursor-pointer">
                {{ webshop.name }}<span class="text-2xs bg-red border border-yellow shadow-double-tip text-white font-bold rounded-sm px-1.5 ml-3 inline align-middle">{{ __('webshop-search.tip') }}</span>
              </div>
            </li>
          </ComboboxOption>
          <ComboboxOption
            v-for="(webshop, i) in searchedWebshops"
            :key="`webshop-search-${i}`"
            v-slot="{ active, selected }"
            :value="route('frontend.webshop.show', webshop.slug)"
            as="template"
          >
            <li
              class="py-1 relative hover:underline"
              :class="{ 'text-red': active || selected }"
            >
              <div class="block cursor-pointer">
                {{ webshop.name }}
              </div>
            </li>
          </ComboboxOption>
        </ul>
      </ComboboxOptions>
    </Combobox>
  </form>
</template>

<script>
import {ref} from 'vue';
import axios from 'axios';
import {Combobox, ComboboxInput, ComboboxOptions, ComboboxOption} from '@headlessui/vue';

export default {
  components: {
    Combobox, ComboboxInput, ComboboxOptions, ComboboxOption,
  },

  props: {
    inputPlaceholder: {
      type: String,
      required: true,
    },

    alternativeStyle: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['navigate'],

  setup() {
    const searchQuery = ref('');
    const isSearching = ref(false);
    const searchedWebshops = ref([]);
    const boosterWebshops = ref([]);
    const minimumSearchLength = 1;

    return {searchQuery, isSearching, searchedWebshops, boosterWebshops, minimumSearchLength};
  },

  data() {
    return {
      timer: null,
      loading: [],
      selectedWebshop: null,
    };
  },

  watch: {
    searchQuery(query) {
      clearTimeout(this.timer);

      if (query && query.length >= this.minimumSearchLength) {
        this.searchedWebshops = [];
        this.boosterWebshops = [];
        this.isSearching = true;

        this.timer = setTimeout(() => {
          this.$nextTick(() => {
            this.searchWebshops(query);
          });
        }, 500);
      }
    },
  },

  methods: {
    clear() {
      this.searchQuery = '';
      this.isSearching = false;
      this.searchedWebshops = [];
    },

    searchWebshops(query) {
      axios.post(this.route('frontend.webshop.search-request', {query}))
        .then(({data}) => {
          this.searchedWebshops = data.webshops;
          this.boosterWebshops = data.booster_webshops;

          this.isSearching = false;
        });
    },

    showWebshopOrSearchPage(selectedOption) {
      this.$emit('navigate');

      if (selectedOption === null) {
        // enter pressed, no webshop selected
        if (this.searchQuery.length >= this.minimumSearchLength) {
          this.$inertia.get(this.route('frontend.webshop.search', {query: this.searchQuery}));
          return;
        }

        return;
      }

      axios.post(this.route('frontend.api.webshop-search-result'), {
        query: this.searchQuery,
        webshop_count: this.searchedWebshops.length,
      });

      this.$inertia.visit(selectedOption);
    },
  },
};
</script>

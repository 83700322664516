<template>
  <div class="container">
    <MobileBreadcrumbs
      :pages="[
        {name: __('payout.index.title'), href: route('frontend.payout')},
      ]"
      :current-page="__('payout.payout.bank.title')"
    />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4 mb-6">
        <div class="flex flex-wrap">
          <div class="bg-white shadow-box rounded p-3 md:p-0 mb-4 md:shadow-none w-full">
            <DesktopBreadcrumbs
              :pages="[
                {name: __('payout.index.title'), href: route('frontend.payout')},
              ]"
              :current-page="__('payout.payout.bank.title')"
            />

            <h1 class="font-serif text-red mb-2 font-bold md:text-3xl">
              {{ __('payout.payout.bank.title') }}
            </h1>

            <p
              v-if="payoutOptions.length === 0"
              class="text-sm md:text-base"
            >
              {{ __('payout.payout.bank.balance_insufficient', {value: payoutSteps}) }}
            </p>
            <form
              v-else
              class="form md:mt-4"
              @submit.prevent="form.post(route('frontend.payout.bank.store'))"
            >
              <p class="text-sm md:text-base">
                {{ __('payout.payout.bank.text', {step: payoutSteps}) }}
              </p>

              <div class="mb-3">
                <div class="flex flex-wrap">
                  <label class="w-full text-sm block mb-1">
                    {{ __('payout.payout.bank.value') }}:
                  </label>
                  <div class="w-full lg:w-2/4 ">
                    <select v-model="form.value">
                      <option
                        v-for="(label, value) in payoutOptions"
                        :key="`payout-${value}`"
                        :value="value"
                      >
                        {{ label }}
                      </option>
                    </select>
                  </div>
                </div>
                <p
                  v-if="form.errors.value"
                  class="text-red text-xs italic"
                >
                  {{ form.errors.value }}
                </p>
              </div>

              <div class="mb-3">
                <div class="flex flex-wrap mb-3">
                  <label class="w-full text-sm block mb-1">
                    {{ __('payout.payout.bank.account-number') }}
                    (<a
                      :href="route('frontend.account.payout')"
                      class="text-red underline hover:no-underline"
                    >{{ __('payout.payout.bank.change-account-number') }}</a>):
                  </label>
                  <div class="w-full lg:w-2/4">
                    <select v-model="form.account">
                      <option
                        v-for="(bankAccount, i) in bankAccounts"
                        :key="`bank-account-${i}`"
                        :value="bankAccount.id"
                      >
                        {{ bankAccount.iban }}
                      </option>
                    </select>
                  </div>
                </div>
                <p
                  v-if="form.errors.account"
                  class="text-red text-xs italic"
                >
                  {{ form.errors.account }}
                </p>
              </div>

              <div class="mb-3 md:items-center">
                <label class="w-full text-sm block mb-1">
                  {{ __('general.password_account') }}:
                </label>

                <Password
                  v-model="form.password"
                  class="lg:w-2/4"
                />
                <p
                  v-if="form.errors.password"
                  class="text-red text-xs italic"
                >
                  {{ form.errors.password }}
                </p>
              </div>

              <div class="flex flex-wrap md:items-center">
                <div class="w-full md:w-5/6 lg:w-2/4">
                  <button
                    type="submit"
                    class="btn text-sm md:text-base"
                    :disabled="form.processing"
                  >
                    {{ __('payout.payout.bank.button') }}

                    <span class="material-symbols-outlined text-white font-bold">
                      chevron_right
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import Password from '../../Components/Form/Password.vue';

export default {
  components: {Password},
  props: {
    bankAccounts: {
      type: Array,
      required: true,
    },

    payoutSteps: {
      type: String,
      required: true,
    },

    payoutOptions: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const form = useForm({
      value: props.payoutOptions ? Object.keys(props.payoutOptions)[0] : null,
      account: props.bankAccounts[0] ? props.bankAccounts[0].id : null,
      password: '',
    });

    return {
      form,
    };
  },
};
</script>

<template>
  <div
    class="relative w-full block"
    :class="{ 'md:w-5/6 lg:w-2/4': inline }"
  >
    <input
      ref="password"
      :value="modelValue"
      :type="showPassword ? 'text' : 'password'"
      :autocomplete="autocomplete"
      required
      @input="$emit(&quot;update:modelValue&quot;, $event.target.value)"
    >

    <span
      class="absolute top-[7px] right-2 material-symbols-outlined text-black cursor-pointer"
      @click="showPassword = !showPassword"
      v-text="showPassword ? 'visibility_off' : 'visibility'"
    />
  </div>
</template>

<script>
import {ref} from 'vue';

export default {
  props: {
    modelValue: {
      type: String,
      required: false,
      default: null,
    },

    autocomplete: {
      type: String,
      required: false,
      default: 'current-password',
    },

    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  setup() {
    return {
      showPassword: ref(false),
    };
  },
};
</script>

<template>
  <div class="container">
    <MobileBreadcrumbs
      :pages="[
        {name: __('webshop-category.index.title'), href: route('frontend.webshop-category.index')},
      ]"
      :current-page="__('webshop-search.results', {count: webshops.meta.pagination.total, query})"
    />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4">
        <div class="mb-6">
          <DesktopBreadcrumbs
            :pages="[
              {name: __('webshop-category.index.title'), href: route('frontend.webshop-category.index')},
            ]"
            :current-page="__('webshop-search.results', {count: webshops.meta.pagination.total, query})"
          />

          <h1 class="text-white font-bold font-serif mb-4 text-center md:text-red md:text-3xl md:text-left">
            {{ __('webshop-search.title') }}
          </h1>

          <WebshopSearchBar
            :input-placeholder="__('webshop-category.index.search', {amount: webshops.meta.pagination.total})"
            :initial-query="query"
          />
        </div>

        <h2 class="text-white font-bold font-serif mb-4 text-center md:text-left md:text-black md:text-2xl">
          <i18n-t
            keypath="webshop-search.results"
            scope="global"
          >
            <template #count>
              <span class="text-red">
                {{ webshops.meta.pagination.total }}
              </span>
            </template>
            <template #query>
              {{ query }}
            </template>
          </i18n-t>
        </h2>

        <div class="grid grid-cols-2 xl:grid-cols-4 gap-3">
          <WebshopBlock
            v-for="(webshop, i) in webshops.data"
            :key="`webshop-${i}`"
            :webshop="webshop"
          />
        </div>

        <div
          v-if="webshops?.meta?.pagination?.links.length > 3"
          class="text-sm w-full mt-4 md:mt-12"
        >
          <div class="border-gray-200 border flex flex-wrap justify-center divide-solid divide-x divide-gray-200 items-center bg-white md:w-3/6 md:mx-auto">
            <template
              v-for="(link, i) in webshops?.meta?.pagination?.links ?? []"
              :key="`page-link-${i}`"
            >
              <Link
                v-if="link.label === __('pagination.previous')"
                :href="webshops.meta.pagination.prev_page_url ?? '#'"
                class="w-1/12 text-center py-2"
                :class="{
                  'font-bold': link.active,
                  'pointer-events-none': webshops.meta.pagination.current_page === 1,
                }"
              >
                <span class="material-symbols-outlined align-middle">
                  chevron_left
                </span>
              </Link>
              <Link
                v-else-if="link.label === __('pagination.next')"
                class="w-1/12 text-center py-2"
                :href="webshops.meta.pagination.next_page_url ?? '#'"
                :class="{
                  'font-bold': link.active,
                  'pointer-events-none': webshops.meta.pagination.current_page === webshops.meta.pagination.last_page,
                }"
              >
                <span class="material-symbols-outlined align-middle">
                  chevron_right
                </span>
              </Link>
              <Link
                v-else
                :href="link.url ?? '#'"
                class="w-1/12 text-center py-2"
                :class="{ 'font-bold': link.active }"
              >
                {{ link.label }}
              </Link>
            </template>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import WebshopSearchBar from '../../Components/Webshop/SearchBar.vue';
import WebshopBlock from '../../Components/Webshop/Block.vue';

export default {
  components: {
    WebshopSearchBar,
    WebshopBlock,
  },

  props: {
    webshops: {
      type: Object,
      required: true,
    },

    query: {
      type: String,
      required: true,
    },
  },

  mounted() {
    axios.post(this.route('frontend.api.webshop-search-result'), {
      query: this.query,
      webshop_count: this.webshops.meta.pagination.total,
    });
  },
};
</script>

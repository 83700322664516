<template>
  <div class="container">
    <MobileBreadcrumbs :current-page="__('webshop-category.index.title')" />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4">
        <div class="mb-6">
          <DesktopBreadcrumbs :current-page="__('webshop-category.index.title')" />

          <h1 class="text-white font-bold font-serif mb-4 text-center md:text-red md:text-3xl md:text-left">
            {{ __('webshop-category.index.search') }}
          </h1>

          <SearchBar :input-placeholder="__('webshop-category.index.search', {amount: webshopsCount})" />
        </div>

        <h2 class="text-white font-bold font-serif mb-4 text-center md:text-left md:text-black md:text-2xl">
          {{ __('webshop-category.index.top') }}
        </h2>

        <div class="grid grid-cols-2 xl:grid-cols-4 gap-3">
          <WebshopBlock
            v-for="(webshop, i) in webshops.data"
            :key="`webshop-${i}`"
            :webshop="webshop"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SearchBar from '../../Components/Webshop/SearchBar.vue';
import WebshopBlock from '../../Components/Webshop/Block.vue';

export default {
  components: {
    SearchBar, WebshopBlock,
  },

  props: {
    webshopsCount: {
      type: String,
      required: true,
    },

    webshops: {
      type: Object,
      required: true,
    },
  },
};
</script>

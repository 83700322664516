<template>
  <div class="container">
    <MobileBreadcrumbs
      :pages="[
        {name: __('account.overview.title'), href: route('frontend.account')},
      ]"
      :current-page="__('account.payout.breadcrumb-title')"
    />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4 mb-6">
        <DesktopBreadcrumbs
          :pages="[
            {name: __('account.overview.title'), href: route('frontend.account')},
          ]"
          :current-page="__('account.payout.breadcrumb-title')"
        />

        <h1 class="text-white font-bold font-serif mb-4 text-center md:text-red md:text-3xl md:text-left">
          {{ __('account.payout.title') }}
        </h1>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-8 flex">
          <div class="order-1 md:order-2">
            <span class="hidden md:block text-red font-serif font-bold mb-4">
              {{ __('account.payout.existing') }}
            </span>

            <div
              v-for="(bankAccount, i) in bankAccounts"
              :key="`bank-account-${i}`"
              class="bg-white shadow-box rounded p-3 mb-2 md:mb-4"
            >
              <div class="relative w-full">
                <span
                  v-if="bankAccount.isVerified"
                  class="inline-block text-2xs pt-1 pb-0.5 px-2 rounded mb-2 bg-red text-white"
                >
                  {{ __('account.payout.bank-account.verified') }}
                </span>
                <span
                  v-else
                  class="inline-block text-2xs pt-1 pb-0.5 px-2 rounded mb-2 bg-gray-200 text-black"
                >
                  {{ __('account.payout.bank-account.not-verified') }}
                </span>

                <span class="block font-bold">
                  {{ bankAccount.iban }}
                </span>

                <span class="block">
                  {{ bankAccount.name }}
                </span>

                <Link
                  v-if="!bankAccount.isVerified"
                  method="post"
                  :data="{id: bankAccount.id}"
                  :href="route('frontend.account.payout.bank.resend-verification')"
                  as="button"
                  class="block text-red underline hover:no-underline"
                >
                  {{ __('account.payout.bank-account.resend-verification-mail') }}
                </Link>

                <DeleteBankAccountModal :bank-account-id="bankAccount.id" />
              </div>
            </div>
          </div>

          <div class="order-2 md:order-1">
            <div class="bg-white shadow-box rounded p-3 md:p-4 mb-4 w-full">
              <span class="text-red font-serif font-bold mb-4 block">
                {{ __('account.payout.new') }}
              </span>

              <form
                class="form md:mt-4"
                @submit.prevent="form.post(route('frontend.account.payout.bank.store'), {
                  onSuccess: () => form.reset(),
                })"
              >
                <div class="mb-3 md:items-center">
                  <label class="w-full text-sm block mb-1 md:font-bold">
                    {{ __('general.bank-account-holder-name') }}:
                  </label>

                  <div class="w-full">
                    <input
                      v-model="form.name"
                      type="text"
                      autocomplete="name"
                      required
                    >
                  </div>
                  <p
                    v-if="form.errors.name"
                    class="text-red text-xs italic"
                  >
                    {{ form.errors.name }}
                  </p>
                </div>

                <div class="mb-3 md:items-center">
                  <label class="w-full text-sm block mb-1 md:font-bold">
                    {{ __('general.iban') }}
                  </label>

                  <div class="w-full ">
                    <input
                      v-model="iban"
                      type="text"
                      name="iban"
                      autocomplete="cc-number"
                      required
                    >
                  </div>
                  <p
                    v-if="form.errors.iban"
                    class="text-red text-xs italic"
                  >
                    {{ form.errors.iban }}
                  </p>
                </div>

                <div class="md:items-center">
                  <div class="w-full">
                    <button
                      :disabled="form.processing"
                      type="submit"
                      class="btn text-sm md:text-base"
                    >
                      {{ __('account.payout.form.button') }}

                      <span class="material-symbols-outlined text-white font-bold">
                        chevron_right
                      </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import DeleteBankAccountModal from './Components/DeleteBankAccountModal.vue';

export default {
  components: {
    DeleteBankAccountModal,
  },

  props: {
    bankAccounts: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const form = useForm({
      name: '',
      iban: '',
    });

    return {form};
  },

  computed: {
    iban: {
      get () {
        return this.form.iban;
      },

      set (value) {
        this.form.iban = value.replace(/\s/g, '').toUpperCase();
      },
    },
  },
};
</script>

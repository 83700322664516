<template>
  <div class="container">
    <div class="flex flex-wrap md:bg-white md:p-4 lg:p-8 rounded-br-md  rounded-bl-md">
      <section class="md:pr-6 w-full md:w-3/4">
        <div class="flex flex-wrap">
          <div class="bg-white shadow-box rounded p-3 mb-4 md:mb-0 md:shadow-none w-full">
            <h1 class="font-serif text-red mb-2 font-bold md:text-3xl">
              {{ __('index.guest.title', {starting_balance: startingBalance}) }}
            </h1>
            <span class="font-bold mb-1 block">{{ __('index.guest.reasons_title') }}</span>

            <ul class="checkmark mb-4">
              <li
                v-for="(reason, i) in $tm('index.guest.reasons')"
                :key="`reason-${i}`"
              >
                {{ $rt(reason, {
                  starting_balance: startingBalance,
                  tell_a_friend_bonus: tellAFriendBonus,
                  email_click_value: emailClickValue,
                  minimum_payout_value: minimumPayoutValue,
                }) }}
              </li>
            </ul>

            <Link
              :href="route('frontend.register')"
              class="btn md:!w-[auto] text-sm md:text-base md:inline-flex md:px-12"
            >
              {{ __('login.register_now_button') }}

              <span class="material-symbols-outlined text-white font-bold">
                chevron_right
              </span>
            </Link>
          </div>

          <div class="bg-white shadow-box rounded p-3 mb-4 md:mb-0 md:shadow-none w-full md:mt-4">
            <h1 class="font-serif text-red mb-2 font-bold">
              {{ __('index.guest.subtitle') }}
            </h1>
            <div class="grid grid-cols-3 md:grid-cols-6 xl:grid-cols-9 gap-4 items-center mt-4">
              <Link
                v-for="webshop in famousWebshops"
                :key="webshop.id"
                :href="route('frontend.webshop.show', webshop.slug)"
              >
                <img
                  :src="webshop.logo.url"
                  :alt="webshop.name"
                  :title="webshop.name"
                  class="mx-auto"
                >
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section class="sidebar w-full md:w-1/4 p-0.5">
        <div class="hidden md:block d-border mb-4">
          <div class="p-3 md:p-2 bg-yellow text-black font-bold font-serif">
            {{ __('general.login') }}
          </div>

          <div class="p-3 bg-white">
            <form
              class="form"
              @submit.prevent="$refs.recaptchaForm.FormSubmit()"
            >
              <div class="flex flex-wrap mb-3 md:items-center">
                <label class="w-full text-sm block mb-1 md:mb-0 md:font-bold">{{ __('general.emailaddress') }}:</label>
                <div class="w-full">
                  <input
                    v-model="form.email"
                    type="email"
                    autocomplete="email"
                    required
                  >

                  <p
                    v-if="form.errors.email"
                    class="text-red text-xs italic"
                  >
                    {{ form.errors.email }}

                    <button
                      v-if="form.errors.email === __('auth.not_exists_or_not_verified')"
                      class="underline hover:no-underline"
                      @click.prevent="form.post(route('frontend.login.resend-email-verification'))"
                    >
                      {{ __('auth.verification_email_link') }}
                    </button>
                  </p>
                </div>
              </div>

              <div class="flex flex-wrap mb-3 md:items-center">
                <label class="w-full text-sm block mb-1 md:mb-0 md:font-bold">{{ __('general.password') }}:</label>

                <Password v-model="form.password" />

                <p
                  v-if="form.errors.password"
                  class="text-red text-xs italic"
                >
                  {{ form.errors.password }}
                </p>
              </div>

              <div class="relative flex items-start mb-3">
                <div class="flex items-center h-5">
                  <input
                    id="remember"
                    v-model="form.remember"
                    name="remember"
                    type="checkbox"
                  >
                </div>
                <div class="ml-2 text-sm">
                  <label
                    for="remember"
                    class="font-medium text-gray-700"
                  >
                    {{ __('login.remember') }}
                  </label>
                </div>
              </div>

              <HiddenRecaptcha
                ref="recaptchaForm"
                :form="form"
                @success="recaptchaSuccess"
              />
              <div class="flex flex-wrap md:items-center mb-3">
                <div class="w-full">
                  <button
                    type="submit"
                    class="btn text-sm md:text-base"
                    :disabled="form.processing"
                  >
                    {{ __('login.button') }}
                    <span class="material-symbols-outlined text-white font-bold">
                      chevron_right
                    </span>
                  </button>
                </div>
              </div>

              <Link
                :href="route('frontend.password-forgot')"
                class="text-red underline hover:no-underline"
              >
                {{ __('login.password_forgot') }}
              </Link>
            </form>
          </div>
        </div>

        <div class="d-border">
          <div class="p-3 md:p-2 bg-yellow text-black font-bold font-serif">
            {{ __('index.guest.payouts') }}
          </div>

          <div class="p-3 bg-white">
            <i18n-t
              keypath="index.guest.payouts_text"
              scope="global"
            >
              <template #amount>
                <span class="font-bold text-red">{{ summedDonePayoutsAmount }}</span>
              </template>
            </i18n-t>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import Password from '../../Components/Form/Password.vue';
import HiddenRecaptcha from '../../Components/Form/HiddenRecaptcha.vue';

export default {
  components: {
    Password,
    HiddenRecaptcha,
  },

  props: {
    famousWebshops: {
      type: Object,
      required: true,
    },

    summedDonePayoutsAmount: {
      type: String,
      required: true,
    },

    startingBalance: {
      type: String,
      required: true,
    },

    tellAFriendBonus: {
      type: String,
      required: true,
    },

    emailClickValue: {
      type: String,
      required: true,
    },

    minimumPayoutValue: {
      type: String,
      required: true,
    },
  },

  setup() {
    const form = useForm({
      email: null,
      password: null,
      remember: true,
      recaptcha: '',
    });

    return {form};
  },

  methods: {
    recaptchaSuccess: function (mutatedForm) {
      mutatedForm.post(this.route('frontend.login.store'), {
        preserveScroll: true,
      });
    },

  },
};
</script>

<template>
  <Component
    :is="type"
    v-model="value"
    :question="question.question"
    :max-selected-answers="question.max_selected_answers"
    :answers="question.answers"
  />
</template>

<script>
import SelectField from '../../Components/ProfileQuestions/SelectField.vue';
import MultiSelectField from '../../Components/ProfileQuestions/MultiSelectField.vue';

export default {
  props: {
    modelValue: {
      type: Array,
      required: false,
      default: () => [],
    },

    question: {
      type: Object,
      required: true,
    },
  },

  emits: ['update:modelValue', 'validation-error'],

  computed:{
    value:{
      get() {
        return this.modelValue;
      },

      set(value) {
        this.$emit('update:modelValue', value);

        if (this.question.max_selected_answers && value.length > this.question.max_selected_answers) {
          this.$emit('validation-error', this.$t('profile-questions.questions.max-selected-answers', {max: this.question.max_selected_answers}));
        } else {
          this.$emit('validation-error', null);
        }
      },
    },

    type() {
      return this.question.has_multiple_answers ? MultiSelectField : SelectField;
    },
  },
};
</script>

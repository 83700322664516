<template>
  <div class="container">
    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <section class="w-full">
        <div class="flex flex-wrap">
          <div class="bg-white shadow-box rounded p-3 mb-4 md:mb-0 md:shadow-none w-full text-center">
            <span
              v-if="hasError"
              class="material-symbols-outlined text-6xl text-red mb-4"
            >cancel</span>

            <h1 class="font-serif text-red mb-2 font-bold md:text-3xl">
              {{ __('mailings.advertiser-check.title') }}
            </h1>

            <p class="mb-4 mx-auto max-w-xl">
              <span
                v-if="success"
                class="text-green"
              >
                {{ message }}
              </span>
              <span
                v-else
                class="text-red"
              >
                {{ message }}
              </span>
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import CleanLayout from '../../Layout/Clean.vue';

export default {
  layout: CleanLayout,

  props: {
    message: {
      type: String,
      required: true,
    },

    success: {
      type: Boolean,
      required: true,
    },

    hasError: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<template>
  <span
    v-for="i in fullStars"
    :key="`full-star-${i}`"
    class="material-symbols-outlined text-yellow"
  >
    star_rate
  </span>
  <span
    v-for="i in halfStars"
    :key="`half-star-${i}`"
    class="material-symbols-outlined text-yellow"
  >
    star_rate_half
  </span>
  <span
    v-for="i in emptyStars"
    :key="`empty-star-${i}`"
    class="material-symbols-outlined text-gray-300"
  >
    star_rate
  </span>
</template>

<script>
export default {
  props: {
    fullStars: {
      type: Number,
      required: true,
    },

    halfStars: {
      type: Number,
      required: true,
    },

    emptyStars: {
      type: Number,
      required: true,
    },
  },
};
</script>

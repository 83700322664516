<template>
  <div class="webshop text-center bg-white p-2 md:p-4 shadow-box rounded flex flex-wrap items-center">
    <a
      :href="route('frontend.clickout.free-action', freeAction.slug)"
      target="_blank"
      class="block mb-3"
    >
      <img
        v-if="freeAction.logo"
        :src="freeAction.logo.url"
        :alt="freeAction.name"
        :title="freeAction.name"
      >
      <span v-else>
        {{ freeAction.name }}
      </span>
    </a>

    <span class="block text-sm text-red font-bold w-full">
      {{ __cashback(freeAction.usePoints, 'cashback.max.single', {value: freeAction.cashback}) }}
    </span>
    <span class="text-sm leading-1 block">
      {{ freeAction.categories[0].name }}
    </span>

    <a
      :href="route('frontend.clickout.free-action', freeAction.slug)"
      target="_blank"
      class="btn mt-3 text-xs md:text-sm"
    >
      {{ __('free-action.block.button') }}

      <span class="material-symbols-outlined text-white font-bold">
        chevron_right
      </span>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    freeAction: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <label class="w-full text-sm block mb-1 md:font-bold lg:w-2/4">
    {{ label }}:
  </label>
  <div class="grid grid-cols-3 gap-2 w-full md:w-5/6 lg:w-2/4">
    <select
      :value="day"
      :name="`${name}_day`"
      @change="$emit('update:day', $event.target.value)"
    >
      <option
        v-for="(optionLabel, value) in dayOptions"
        :key="`${name}-day-${value}`"
        :value="value"
      >
        {{ optionLabel }}
      </option>
    </select>

    <select
      :value="month"
      :name="`${name}_month`"
      @change="$emit('update:month', $event.target.value)"
    >
      <option
        v-for="(optionLabel, value) in monthOptions"
        :key="`${name}-month-${value}`"
        :value="value"
      >
        {{ optionLabel }}
      </option>
    </select>

    <select
      :value="year"
      :name="`${name}_year`"
      @change="$emit('update:year', $event.target.value)"
    >
      <option
        v-for="(optionLabel, value) in yearOptions"
        :key="`${name}-year-${value}`"
        :value="value"
      >
        {{ optionLabel }}
      </option>
    </select>
  </div>

  <p
    v-if="errors && errors[name]"
    class="text-red text-xs italic"
  >
    {{ errors[name] }}
  </p>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    dayOptions: {
      type: Object,
      required: true,
    },

    monthOptions: {
      type: Object,
      required: true,
    },

    yearOptions: {
      type: Object,
      required: true,
    },

    year: {
      type: [Number, String],
      required: false,
      default: null,
    },

    month: {
      type: [Number, String],
      required: false,
      default: null,
    },

    day: {
      type: [Number, String],
      required: false,
      default: null,
    },

    errors: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  emits: ['update:day', 'update:month', 'update:year'],

};
</script>

<template>
  <div class="container">
    <MobileBreadcrumbs
      :pages="[
        {name: __('mailings.breadcrumb-title'), href: route('frontend.mailings.index')},
      ]"
      :current-page="mailing.subject"
    />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4 mb-6 pb-12">
        <DesktopBreadcrumbs
          :pages="[
            {name: __('mailings.breadcrumb-title'), href: route('frontend.mailings.index')},
          ]"
          :current-page="mailing.subject"
        />

        <h1 class="text-white font-bold font-serif text-center mb-4 md:text-red md:text-3xl md:text-left">
          {{ mailing.subject }}
        </h1>

        <div class="flex flex-col min-h-screen md:min-h-full bg-white">
          <template v-if="loadingMailPreview">
            <div class="absolute flex items-center z-1 mt-4 text-red px-4 md:px-0">
              {{ __('mailings.loading-mail') }}

              <span class="material-symbols-outlined animate-spin ml-2">
                autorenew
              </span>
            </div>
          </template>
          <iframe
            :src="route('frontend.mailings.preview', mailing.id)"
            class="grow"
            :class="{ 'hidden': loadingMailPreview }"
            :style="{ 'height': `${iframeHeight}px` }"
            @load="iframeLoaded($event)"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mailing: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loadingMailPreview: true,
      iframeHeight: 0,
    };
  },

  methods: {
    iframeLoaded(e) {
      this.loadingMailPreview = false;

      this.$nextTick(() => this.iframeHeight = e.target.contentWindow.document.documentElement.scrollHeight);
    },
  },
};
</script>

<template>
  <div class="container">
    <MobileBreadcrumbs :current-page="__('register.title')" />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <section class="w-full">
        <div class="flex flex-wrap">
          <div class="bg-white shadow-box rounded p-3 mb-4 md:mb-0 md:shadow-none w-full">
            <DesktopBreadcrumbs :current-page="__('register.title')" />

            <h1 class="font-serif text-red mb-2 font-bold md:text-3xl">
              {{ __('register.title') }}
            </h1>

            <form
              class="form md:w-2/6 mt-4"
              @submit.prevent="$refs.recaptchaForm.FormSubmit()"
            >
              <div class="flex flex-wrap mb-3 md:items-center">
                <label class="w-full text-sm block mb-1 md:font-bold">
                  {{ __('general.gender') }}:
                </label>
                <div class="w-full">
                  <select
                    v-model="form.gender"
                    name="gender"
                    required
                  >
                    <option
                      value=""
                      disabled
                    >
                      {{ __('general.gender-placeholder') }}
                    </option>
                    <option
                      v-for="(gender, i) in genders"
                      :key="`gender-${i}`"
                      :value="gender.id"
                    >
                      {{ gender.name }}
                    </option>
                  </select>
                </div>
                <p
                  v-if="form.errors.gender"
                  class="text-red text-xs italic"
                >
                  {{ form.errors.gender }}
                </p>
              </div>

              <div class="flex flex-wrap mb-3 md:items-center">
                <label class="w-full text-sm block mb-1 md:font-bold">
                  {{ __('general.first_name') }}:
                </label>
                <div class="w-full">
                  <input
                    v-model="form.first_name"
                    type="text"
                    name="first_name"
                    autocomplete="given-name"
                    required
                  >
                </div>
                <p
                  v-if="form.errors.first_name"
                  class="text-red text-xs italic"
                >
                  {{ form.errors.first_name }}
                </p>
              </div>

              <div class="flex flex-wrap mb-3 md:items-center">
                <label class="w-full text-sm block mb-1 md:font-bold">
                  {{ __('general.emailaddress') }}:
                </label>
                <div class="w-full">
                  <input
                    v-model="form.email"
                    type="email"
                    name="email"
                    autocomplete="email"
                    required
                  >
                </div>
                <p
                  v-if="form.errors.email"
                  class="text-red text-xs italic"
                >
                  {{ form.errors.email }}
                </p>
              </div>

              <div class="flex flex-wrap mb-3 md:items-center">
                <BirthdateField
                  v-model:day="form.birth_date_day"
                  v-model:month="form.birth_date_month"
                  v-model:year="form.birth_date_year"
                  v-model:parental_approval="form.parental_approval"
                  :day-options="days"
                  :month-options="months"
                  :year-options="years"
                  :minimum-age="minimumAge"
                  :errors="form.errors"
                />
              </div>

              <div class="flex flex-wrap mb-3 md:items-center">
                <label class="w-full text-sm block mb-1 md:font-bold">
                  {{ __('general.password') }}:
                </label>

                <Password v-model="form.password" />

                <div
                  v-if="form.errors.password"
                  class="text-red text-xs italic mt-2"
                >
                  <p class="mb-1">
                    {{ __('register.errors.password_requirements') }}
                  </p>
                  <ul class="list-disc space-y-1 ml-4">
                    <li
                      v-for="error in form.errors.password.split('|')"
                      :key="error"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </div>
              </div>

              <!--
              <div class="flex flex-wrap mb-3 md:items-center">
                <label class="w-full text-sm block mb-1 md:font-bold">
                  Username:
                </label>
                <div class="w-full">
                  <input
                    type="text"
                    disabled
                    placeholder="JasperWOLF"
                    class="placeholder-black"
                  >
                </div>
              </div>
              -->

              <div class="flex flex-wrap mb-4">
                <p>
                  {{ __('register.terms') }}
                  <br>
                  <br>
                  <i18n-t
                    keypath="register.term-links"
                    scope="global"
                  >
                    <template #general>
                      <Link
                        :href="route('frontend.terms')"
                        class="text-red underline hover:no-underline"
                      >
                        {{ __('register.term-link-general') }}
                      </Link>
                    </template>
                    <template #privacy_policy>
                      <Link
                        :href="route('frontend.privacy-policy')"
                        class="text-red underline hover:no-underline"
                      >
                        {{ __('register.term-link-privacy-policy') }}
                      </Link>
                    </template>
                    <template #cookie_policy>
                      <Link
                        :href="route('frontend.cookie-policy')"
                        class="text-red underline hover:no-underline"
                      >
                        {{ __('register.term-link-cookie-policy') }}
                      </Link>
                    </template>
                  </i18n-t>
                </p>
              </div>

              <HiddenRecaptcha
                ref="recaptchaForm"
                :form="form"
                @success="recaptchaSuccess"
              />

              <div class="flex flex-wrap md:items-center">
                <div class="w-full">
                  <button
                    type="submit"
                    class="btn text-sm md:text-base"
                    :disabled="form.processing"
                  >
                    {{ __('register.button') }}

                    <span class="material-symbols-outlined text-white font-bold">
                      chevron_right
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import BirthdateField from '../../Components/Form/BirthdateField.vue';
import Password from '../../Components/Form/Password.vue';
import HiddenRecaptcha from '../../Components/Form/HiddenRecaptcha.vue';

export default {
  components: {
    BirthdateField,
    Password,
    HiddenRecaptcha,
  },

  props: {
    days: {
      type: Object,
      required: true,
    },

    months: {
      type: Object,
      required: true,
    },

    years: {
      type: Object,
      required: true,
    },

    genders: {
      type: Array,
      required: true,
    },

    minimumAge: {
      type: Number,
      required: true,
    },
  },

  setup() {
    const form = useForm({
      gender: '',
      first_name: '',
      email: '',
      birth_date_year: '',
      birth_date_month: '',
      birth_date_day: '',
      password: '',
      parental_approval: null,
      recaptcha: '',
    });

    return {
      form,
    };
  },

  methods: {
    recaptchaSuccess: function (mutatedForm) {
      mutatedForm.post(this.route('frontend.register.store'));
    },
  },
};
</script>

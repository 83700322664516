<template>
  <GeldraceHead />

  <FlashMessages />

  <header>
    <NotificationBar />

    <div class="relative container flex flex-wrap justify-between items-center py-4 md:py-0">
      <BannerHeader />

      <div
        v-if="$page.props.auth.member"
        class="md:hidden"
      >
        <AlreadyParticipatedModal ref="alreadyParticipated" />

        <div class="flex justify-end items-center space-x-4 h-full">
          <Popover
            v-slot="{ open }"
            as="div"
          >
            <PopoverButton
              as="div"
              class="leading-none cursor-pointer"
            >
              <span class="material-symbols-outlined text-white font-bold text-3xl">
                search
              </span>
            </PopoverButton>
            <PopoverPanel
              v-slot="{ close }"
              as="div"
              :open="open"
            >
              <div class="z-50 absolute top-[70px] left-0 w-screen bg-red-900 py-3 px-4">
                <SearchBar
                  :input-placeholder="__('menu.webshop-search', {amount: 1234})"
                  :alternative-style="true"
                  @navigate="close"
                />
              </div>
            </PopoverPanel>
          </Popover>

          <Popover
            v-slot="{ open }"
            as="div"
          >
            <PopoverButton
              as="div"
              class="leading-none cursor-pointer"
            >
              <span
                v-if="open"
                class="material-symbols-outlined text-white font-bold text-4xl"
              >
                menu_open
              </span>
              <span
                v-else
                class="material-symbols-outlined text-white font-bold text-4xl"
              >
                menu
              </span>
            </PopoverButton>
            <PopoverPanel
              v-slot="{ close }"
              as="div"
              :open="open"
              class="absolute top-[70px] left-0 "
            >
              <div class="absolute z-50 w-screen h-screen bg-white whitespace-nowrap">
                <div class="flex flex-col font-medium divide-y divide-yellow-500">
                  <Link
                    :href="route('frontend.account')"
                    class="flex items-center group p-4"
                    @click="close"
                  >
                    <span class="material-symbols-outlined text-lg mr-2">
                      chevron_right
                    </span>
                    <span class="group-hover:underline">
                      {{ __('menu.account-settings') }}
                    </span>
                  </Link>

                  <Link
                    :href="route('frontend.balance')"
                    class="flex items-center group p-4"
                    @click="close"
                  >
                    <span class="material-symbols-outlined text-lg mr-2">
                      chevron_right
                    </span>
                    <span class="group-hover:underline">
                      {{ __('menu.balance') }}
                    </span>
                  </Link>

                  <Link
                    :href="route('frontend.payout')"
                    class="flex items-center group p-4"
                    @click="close"
                  >
                    <span class="material-symbols-outlined text-lg mr-2">
                      chevron_right
                    </span>
                    <span class="group-hover:underline">
                      {{ __('menu.payout') }}
                    </span>
                  </Link>

                  <Link
                    :href="route('frontend.faq')"
                    class="flex items-center group p-4"
                    @click="close"
                  >
                    <span class="material-symbols-outlined text-lg mr-2">
                      chevron_right
                    </span>
                    <span class="group-hover:underline">
                      {{ __('menu.faq') }}
                    </span>
                  </Link>

                  <Link
                    :href="route('frontend.contact')"
                    class="flex items-center group p-4"
                    @click="close"
                  >
                    <span class="material-symbols-outlined text-lg mr-2">
                      chevron_right
                    </span>
                    <span class="group-hover:underline">
                      {{ __('menu.contact') }}
                    </span>
                  </Link>

                  <Link
                    :href="route('frontend.logout')"
                    method="post"
                    as="button"
                    class="flex items-center font-medium group p-4 text-left"
                    @click="close"
                  >
                    <span class="material-symbols-outlined text-lg mr-2">
                      chevron_right
                    </span>
                    <span class="group-hover:underline">
                      {{ __('general.logout') }}
                    </span>
                  </Link>
                </div>
              </div>
            </PopoverPanel>
          </Popover>
        </div>
      </div>
      <div
        v-else
        class="flex md:hidden align-center text-sm"
      >
        <LoginModal
          v-if="!$page.props.auth.member"
          ref="loginModal"
        />

        <Link
          :href="route('frontend.register')"
          class="text-white hover:underline ml-2"
        >
          {{ __('login.register_button') }}
        </Link>
      </div>
    </div>
  </header>

  <slot />

  <GeldraceFoot />
</template>

<script>
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/vue';
import GeldraceHead from './Components/Head.vue';
import GeldraceFoot from './Components/Foot.vue';
import FlashMessages from './Components/FlashMessages.vue';
import LoginModal from '../Components/LoginModal.vue';
import AlreadyParticipatedModal from '../Components/AlreadyParticipatedModal.vue';
import SearchBar from '../Components/Webshop/SearchBar.vue';
import NotificationBar from '../Components/NotificationBar.vue';
import BannerHeader from './Components/BannerHeader.vue';

export default {
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    GeldraceHead,
    BannerHeader,
    GeldraceFoot,
    FlashMessages,
    LoginModal,
    AlreadyParticipatedModal,
    SearchBar,
    NotificationBar,
  },

  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
};
</script>

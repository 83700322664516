<template>
  <div class="container">
    <MobileBreadcrumbs :current-page="__('click-action.title')" />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4">
        <div class="mb-6">
          <DesktopBreadcrumbs :current-page="__('click-action.title')" />
        </div>

        <h1 class="text-white text-center md:text-left font-bold font-serif md:text-red md:text-3xl mb-4">
          {{ __('click-action.top') }}
        </h1>

        <template v-if="clickActions.length > 0">
          <div class="grid grid-cols-2 xl:grid-cols-4 gap-3">
            <ClickActionBlock
              v-for="clickAction in clickActions"
              :key="`click-action-${clickAction.id}`"
              :click-action="clickAction"
            />
          </div>
        </template>
        <template v-else>
          <div class="mb-12">
            <p class="font-medium">
              <i>{{ __('click-action.no-click-actions') }}</i>
            </p>
          </div>
        </template>
      </section>
    </div>
  </div>
</template>

<script>
import ClickActionBlock from '../../Components/ClickActionBlock.vue';

export default {
  components: {
    ClickActionBlock,
  },

  props: {
    clickActions: {
      type: Array,
      required: true,
    },
  },
};
</script>

<template>
  <Modal
    ref="modal"
    :title="__('global.already-participated.title')"
  >
    <template #modal-content="{ closeModal }">
      <div class="w-full">
        {{ __('global.already-participated.text') }}

        <a
          v-if="url"
          :href="url"
          target="_blank"
          class="btn mt-3 text-sm md:text-base"
          @click="closeModal"
        >
          {{ __('global.already-participated.button') }}

          <span class="material-symbols-outlined text-white font-bold">
            chevron_right
          </span>
        </a>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from './Modal.vue';

export default {
  components: {
    Modal,
  },

  setup() {
    return {
      url: null,
    };
  },

  methods: {
    openModal: function (url) {
      this.url = url;
      this.$refs.modal.openModal();
    },
  },
};
</script>

<template>
  <div class="container">
    <MobileBreadcrumbs :current-page="__('account.unlock-member.title')" />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4 mb-6">
        <DesktopBreadcrumbs :current-page="__('account.unlock-member.title')" />

        <div class="bg-white shadow-box rounded p-3 md:p-0 mb-4 md:shadow-none w-full">
          <h1 class="font-serif text-red mb-2 font-bold md:text-3xl">
            {{ __('account.unlock-member.title') }}
          </h1>

          <form
            class="form mt-4"
            @submit.prevent="form.post(route('frontend.unlock-member.store'), {
              onSuccess: () => form.reset('password'),
            })"
          >
            <div class="flex flex-wrap md:items-center">
              <label class="w-full text-sm block mb-1 md:mb-0 md:w-1/6 md:font-bold">
                {{ __('general.password_confirmation') }}:
              </label>
              <div class="w-full md:w-5/6 lg:w-2/4">
                <Password v-model="form.password" />
              </div>
              <div
                v-if="form.errors.password"
                class="w-full text-red text-xs italic"
              >
                <div class="md:w-1/6 inline-block" />
                <p class="inline-block">
                  {{ form.errors.password }}
                </p>
              </div>
            </div>

            <div class="flex mb-3">
              <div class="hidden md:block md:w-1/6" />
              <div class="w-full md:w-5/6 lg:w-2/4">
                <Link
                  :href="route('frontend.password-forgot')"
                  class="block text-red text-sm underline hover:no-underline w-full mb-2"
                >
                  {{ __('account.unlock-member.forgot-password') }}
                </Link>
              </div>
            </div>

            <div class="flex flex-wrap md:items-center">
              <label class="hidden md:block md:w-1/6 md:font-bold" />
              <div class="w-full md:w-5/6 lg:w-2/4">
                <button
                  type="submit"
                  class="btn text-sm md:text-base"
                  :disabled="form.processing"
                >
                  {{ __('account.unlock-member.button') }}
                  <span class="material-symbols-outlined text-white font-bold">
                    chevron_right
                  </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import Password from '../../Components/Form/Password.vue';

export default {
  components: {
    Password,
  },

  setup() {
    const form = useForm({
      password: '',
    });

    return {
      form,
    };
  },
};
</script>

<template>
  <Modal
    ref="modal"
    :title="__('login.title')"
  >
    <template #modal-button="{ openModal }">
      <button
        class="text-white hover:underline"
        @click="openModal"
      >
        {{ __('login.button') }}
      </button>
    </template>

    <template #modal-content="{ closeModal }">
      <form
        class="form"
        @submit.prevent="$refs.recaptchaForm.FormSubmit()"
      >
        <div class="flex flex-wrap mb-3 md:items-center">
          <label class="w-full text-sm block mb-1 md:mb-0 md:font-bold">
            {{ __('general.emailaddress') }}:
          </label>
          <div class="w-full">
            <input
              v-model="form.email"
              type="email"
              autocomplete="email"
              required
            >

            <p
              v-if="form.errors.email"
              class="text-red text-xs italic"
            >
              {{ form.errors.email }}

              <button
                v-if="form.errors.email === __('auth.not_exists_or_not_verified')"
                class="underline hover:no-underline"
                @click.prevent="form.post(route('frontend.login.resend-email-verification'))"
              >
                {{ __('auth.verification_email_link') }}
              </button>
            </p>
          </div>
        </div>

        <div class="flex flex-wrap mb-3 md:items-center">
          <label class="w-full text-sm block mb-1 md:mb-0 md:font-bold">
            {{ __('general.password') }}:
          </label>

          <Password v-model="form.password" />

          <p
            v-if="form.errors.password"
            class="text-red text-xs italic"
          >
            {{ form.errors.password }}
          </p>
        </div>

        <div class="flex items-start justify-between mb-3 text-sm">
          <div class="flex items-center h-5">
            <input
              id="remember"
              v-model="form.remember"
              name="remember"
              type="checkbox"
            >
            <div class="ml-2">
              <label
                for="remember"
                class="font-medium text-gray-700"
              >
                {{ __('login.remember') }}
              </label>
            </div>
          </div>
          <Link
            :href="route('frontend.password-forgot')"
            class="text-red underline hover:no-underline"
            @click="closeModal"
          >
            {{ __('login.password_forgot') }}
          </Link>
        </div>

        <div class="flex flex-wrap md:items-center mb-3">
          <HiddenRecaptcha
            ref="recaptchaForm"
            class="invisible"
            :form="form"
            @success="recaptchaSuccess"
          />

          <div class="w-full">
            <button
              type="submit"
              class="btn text-sm md:text-base"
              :disabled="form.processing"
            >
              {{ __('login.button') }}

              <span class="material-symbols-outlined text-white font-bold">
                chevron_right
              </span>
            </button>
          </div>
        </div>

        <hr class="w-full h-px my-6 bg-red border-0">

        <div class="flex justify-center">
          <Link
            :href="route('frontend.register')"
            class="text-red underline hover:no-underline"
            @click="closeModal"
          >
            {{ __('login.register') }}
          </Link>
        </div>

        <div class="text-gray-300 text-xs text-center mt-10">
          <i18n-t
            keypath="login.recaptcha"
            scope="global"
          >
            <template #policy>
              <a
                class="underline"
                href="https://policies.google.com/privacy"
                target="_blank"
              >
                {{ __('login.google-policy') }}
              </a>
            </template>
            <template #terms>
              <a
                class="underline"
                href="https://policies.google.com/terms"
                target="_blank"
              >
                {{ __('login.google-terms') }}
              </a>
            </template>
          </i18n-t>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import Modal from './Modal.vue';
import Password from '../Components/Form/Password.vue';
import HiddenRecaptcha from './Form/HiddenRecaptcha.vue';

export default {
  components: {
    Modal,
    Password,
    HiddenRecaptcha,
  },

  setup() {
    const form = useForm({
      email: null,
      password: null,
      remember: true,
      recaptcha: '',
    });

    return {
      form,
    };
  },

  methods: {
    doOpenModal: function () {
      this.$refs.modal.openModal();
    },

    recaptchaSuccess: function (mutatedForm) {
      mutatedForm.post(this.route('frontend.login.store'), {
        preserveScroll: true,
        onSuccess: () => {
          this.closeModal();
          this.form.reset();
        },
      });
    },
  },
};
</script>

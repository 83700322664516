<template>
  <div class="container">
    <MobileBreadcrumbs
      :pages="[
        {name: __('account.overview.title'), href: route('frontend.account')},
      ]"
      :current-page="__('account.change-password.title')"
    />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4 mb-6">
        <div class="flex flex-wrap">
          <div class="bg-white shadow-box rounded p-3 md:p-0 mb-4 md:shadow-none w-full">
            <DesktopBreadcrumbs
              :pages="[
                {name: __('account.overview.title'), href: route('frontend.account')},
              ]"
              :current-page="__('account.change-password.title')"
            />

            <h1 class="font-serif text-red mb-2 font-bold md:text-3xl">
              {{ __('account.change-password.title') }}
            </h1>

            <form
              class="form md:mt-4"
              @submit.prevent="form.post(route('frontend.account.change-password.store'), {
                onSuccess: () => form.reset('new_password_confirmation', 'new_password', 'password'),
              })"
            >
              <div class="mb-3 md:items-center">
                <label class="w-full text-sm block mb-1  md:font-bold lg:w-2/4">
                  {{ __('general.password_current') }}:
                </label>

                <Password
                  v-model="form.password"
                  class="lg:w-2/4"
                />
                <p
                  v-if="form.errors.password"
                  class="text-red text-xs italic"
                >
                  {{ form.errors.password }}
                </p>
              </div>

              <div class="mb-3 md:items-center">
                <label class="w-full text-sm block mb-1  md:font-bold lg:w-2/4">
                  {{ __('general.new_password') }}:
                </label>

                <Password
                  v-model="form.new_password"
                  autocomplete="new-password"
                  class="lg:w-2/4"
                />
                <div
                  v-if="form.errors.new_password"
                  class="text-red text-xs italic mt-2"
                >
                  <p>
                    {{ __('register.errors.password_requirements') }}
                  </p>
                  <ul class="list-disc space-y-1 ml-4">
                    <li
                      v-for="error in form.errors.new_password.split('|')"
                      :key="error"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </div>
              </div>

              <div class="mb-3 md:items-center">
                <label class="w-full text-sm block mb-1  md:font-bold lg:w-2/4">
                  {{ __('general.new_password_confirmation') }}:
                </label>

                <Password
                  v-model="form.new_password_confirmation"
                  autocomplete="new-password"
                  class="lg:w-2/4"
                />
                <p
                  v-if="form.errors.new_password_confirmation"
                  class="text-red text-xs italic"
                >
                  {{ form.errors.new_password_confirmation }}
                </p>
              </div>

              <div class="flex flex-wrap md:items-center">
                <div class="w-full md:w-5/6 lg:w-2/4">
                  <button
                    :disabled="form.processing"
                    type="submit"
                    class="btn text-sm md:text-base"
                  >
                    {{ __('account.change-password.button') }}

                    <span class="material-symbols-outlined text-white font-bold">
                      chevron_right
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import Password from '../../../Components/Form/Password.vue';

export default {
  components: {
    Password,
  },

  setup() {
    const form = useForm({
      password: '',
      new_password: '',
      new_password_confirmation: '',
    });

    return {form};
  },
};
</script>

<template>
  <InertiaHead :title="title ? `${title} - ${__('tenant.name')}` : __('tenant.name')">
    <slot />
  </InertiaHead>
</template>

<script>
import {Head as InertiaHead} from '@inertiajs/vue3';

export default {
  components: {
    InertiaHead,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>

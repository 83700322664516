<template>
  <section
    v-if="$page.props.auth.member"
    class="sidebar w-full md:w-1/4 p-0.5"
  >
    <div
      class="md:block d-border mb-4"
      :class="{ 'hidden': !showStatisticsMobile }"
    >
      <div class="p-3 md:p-2 bg-yellow text-black font-bold font-serif">
        {{ __('menu.statistics') }}
      </div>

      <div class="p-3 bg-white">
        <span class="block">
          {{ __('menu.unread-emails') }}:
          <Link
            :href="route('frontend.mailings.index')"
            class="text-red underline hover:no-underline"
          >
            {{ $page.props.unreadEmailsCount }}
          </Link>
        </span>

        <span class="block">
          {{ __('menu.balance') }}:
          <Link
            :href="route('frontend.balance')"
            class="text-red underline hover:no-underline"
          >
            {{ $page.props.auth.member.balance }}
          </Link>
        </span>

        <span class="block">
          {{ __('menu.unclicked-links') }}:
          <Link
            :href="route('frontend.click-actions.index')"
            class="text-red underline hover:no-underline"
          >
            {{ $page.props.availableClickActionsCount }}
          </Link>
        </span>
      </div>
    </div>

    <div
      class="hidden md:block d-border mb-4"
    >
      <div class="flex justify-between items-center p-3 md:p-2 bg-yellow">
        <div class="text-black font-bold font-serif">
          {{ __('menu.webshops') }}
        </div>

        <Link
          :href="route('frontend.webshop-category.index')"
          class="text-xs text-red font-bold underline hover:no-underline"
        >
          {{ __('menu.show-all-webshops') }}
        </Link>
      </div>

      <div class="p-3 bg-white">
        <SearchBar
          :input-placeholder="__('menu.webshop-search', {amount: $page.props.webshopCount})"
          :alternative-style="true"
        />
      </div>
    </div>

    <Disclosure
      as="div"
      class="d-border mb-4"
      :default-open="!$isMobile || menuDefaultOpen"
    >
      <DisclosureButton
        v-slot="{ open }"
        class="flex justify-between items-center w-full p-3 md:p-2 text-black font-bold font-serif"
        :class="$isMobile && !menuDefaultOpen ? 'bg-white' : 'bg-yellow'"
      >
        <span>
          {{ __('menu.menu') }}
        </span>

        <button class="bg-black text-white text-lg font-bold rounded-full leading-[0]">
          <span
            v-show="open"
            class="material-symbols-outlined"
          >
            expand_less
          </span>
          <span
            v-show="!open"
            class="material-symbols-outlined"
          >
            expand_more
          </span>
        </button>
      </DisclosureButton>

      <DisclosurePanel>
        <ul class="p-3 py-1 bg-white divide-solid divide-y divide-yellow-500">
          <template
            v-for="theme in $page.props.menuWebshopThemes"
            :key="`active-webshop-theme-${theme.slug}`"
          >
            <li class="py-1 tip">
              <Link
                :href="route('frontend.webshop-theme.show', theme.slug)"
                class="hover:underline"
              >
                {{ __('menu.webshop-theme', {webshopTheme: theme.name}) }}
              </Link>
            </li>
          </template>

          <li class="py-1">
            <Link
              :href="route('frontend.earn-cashback')"
              class="hover:underline"
            >
              {{ __('menu.earn-cashback') }}
            </Link>
          </li>

          <li class="py-1">
            <Link
              :href="route('frontend.games.index')"
              class="hover:underline"
            >
              {{ __('menu.games') }}
            </Link>
          </li>

          <li class="py-1">
            <Link
              :href="route('frontend.tell-a-friend.index')"
              class="hover:underline"
            >
              {{ __('menu.referrals') }}
            </Link>
          </li>

          <li class="py-1">
            <Link
              :href="route('frontend.news.index')"
              class="hover:underline align-middle"
            >
              {{ __('menu.news') }}

              <span
                v-if="$page.props.hasUnreadNews"
                class="material-symbols-outlined text-red text-xs inline"
              >circle</span>
            </Link>
          </li>

          <li class="py-1">
            <Link
              :href="route('frontend.payout')"
              class="hover:underline"
            >
              {{ __('menu.payout') }}
            </Link>
          </li>

          <li class="py-1">
            <Link
              :href="route('frontend.account')"
              class="hover:underline"
            >
              {{ __('menu.account-settings') }}
            </Link>
          </li>

          <li class="py-1">
            <Link
              :href="route('frontend.logout')"
              method="post"
              as="button"
              class="hover:underline"
            >
              {{ __('general.logout') }}
            </Link>
          </li>
        </ul>
      </DisclosurePanel>
    </Disclosure>

    <div
      v-if="$page.props.news.length > 0"
      class="md:block d-border"
      :class="{ 'hidden': !showStatisticsMobile }"
    >
      <div class="p-3 md:p-2 bg-yellow text-black font-bold font-serif">
        {{ __('menu.news') }}
      </div>

      <div class="p-3 bg-white">
        <div
          v-for="(newsItem, i) in $page.props.news"
          :key="`sidebar-news-${i}`"
          class="flex flex-wrap"
        >
          <div class="w-2/3 md:w-full lg:w-2/3">
            <a
              :href="route('frontend.news.show', newsItem.id)"
              class="text-red underline hover:no-underline truncate block"
            >
              {{ newsItem.title }}
            </a>
          </div>

          <div class="w-1/3 md:w-full lg:w-1/3">
            <span class="text-xs block text-right text-gray-300 leading-7">
              {{ newsItem.date }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {Disclosure, DisclosureButton, DisclosurePanel} from '@headlessui/vue';
import SearchBar from './Webshop/SearchBar.vue';

export default {
  components: {
    Disclosure, DisclosureButton, DisclosurePanel,
    SearchBar,
  },

  props: {
    menuDefaultOpen: {
      type: Boolean,
      required: false,
      default: false,
    },

    showStatisticsMobile: {
      type: Boolean,
      required: false,
      default: false,
    },

    showNewsMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

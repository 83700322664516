<template>
  <div class="container">
    <MobileBreadcrumbs :current-page="__('content.terms.title')" />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="content w-full md:w-3/4">
        <div class="flex flex-wrap">
          <div class="bg-white shadow-box rounded p-3 mb-4 md:mb-0 md:shadow-none w-full">
            <DesktopBreadcrumbs :current-page="__('content.terms.title')" />

            <h1 class="font-serif text-red mb-4 md:mb-8 font-bold md:text-3xl">
              {{ __('content.terms.title') }}
            </h1>

            <i18n-t
              keypath="content.terms.text"
              scope="global"
            >
              <template #link>
                <a
                  :href="link"
                  target="_blank"
                  class="text-red underline hover:no-underline"
                >
                  {{ __('content.terms.link-text') }}
                </a>
              </template>
            </i18n-t>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      required: true,
    },
  },
};
</script>

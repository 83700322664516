<template>
  <section
    v-for="webshopSuggestion in webshopSuggestions"
    :key="`webshop_suggestion_${webshopSuggestion.id}`"
    class="mb-6 md:mb-12"
  >
    <template v-if="webshopSuggestion.title">
      <h2 class="font-bold font-serif text-2xl mb-4">
        {{ webshopSuggestion.title }}
      </h2>
    </template>

    <div
      class="grid"
      :class="webshopSuggestion.block_type === 'billboard_only' ? 'grid-cols-1' : 'grid-cols-3 xl:grid-cols-4 gap-6'"
    >
      <WebshopBlock
        v-for="webshop in webshopSuggestion.webshops"
        :key="`webshop-${webshop.id}`"
        :webshop="webshop"
        :block-type="webshopSuggestion.block_type"
      />
    </div>
  </section>
</template>

<script>
import WebshopBlock from './Webshop/Block.vue';

export default {
  components: {WebshopBlock},

  props: {
    webshopSuggestions: {
      type: Object,
      required: true,
    },
  },
};
</script>

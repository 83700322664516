<template>
  <div class="container">
    <MobileBreadcrumbs :current-page="__('payout.index.title')" />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4 mb-6">
        <DesktopBreadcrumbs :current-page="__('payout.index.title')" />

        <h1 class="text-white font-bold font-serif mb-4 text-center md:text-red md:text-3xl md:text-left">
          {{ __('payout.index.title') }}
        </h1>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-8">
          <div class="bg-white shadow-box rounded p-3 mb-2 md:mb-0  flex flex-wrap">
            <div>
              <span class="text-red font-serif font-bold mb-2 block w-full">
                {{ __('payout.index.bank.title') }}
              </span>
              <span class="italic block">
                {{ __('payout.index.bank.requirements', {minimum_balance: minimumPayoutValueBank}) }}
              </span>
              <span class="block">
                {{ __('payout.index.bank.text') }}
              </span>
            </div>

            <div class="flex self-end w-full">
              <Link
                :href="route('frontend.payout.bank')"
                class="btn mt-3 text-sm md:text-base"
              >
                {{ __('payout.index.bank.button') }}

                <span class="material-symbols-outlined text-white font-bold">
                  chevron_right
                </span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    minimumPayoutValueBank: {
      type: String,
      required: true,
    },
  },
};
</script>


<template>
  <div class="container">
    <MobileBreadcrumbs
      :pages="[
        {name: __('news.index.title'), href: route('frontend.news.index')},
      ]"
      :current-page="__('news.archive.title')"
    />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4 mb-6">
        <DesktopBreadcrumbs
          :pages="[
            {name: __('news.index.title'), href: route('frontend.news.index')},
          ]"
          :current-page="__('news.archive.title')"
        />

        <h1 class="text-white text-center md:text-left font-bold font-serif md:text-red md:text-3xl mb-4">
          {{ __('news.archive.title') }}
        </h1>

        <div class="bg-white shadow-box rounded p-3 md:p-0 mb-4 md:mb-8 md:shadow-none w-full order-1 space-y-4">
          <div
            v-if="Object.keys(groupedNews).length === 0"
            class="text-center font-bold"
          >
            {{ __('news.archive.empty') }}
          </div>
          <table
            v-for="(newsGroup, key) in groupedNews"
            v-else
            :key="`news-group-${key}`"
            class="text-left"
          >
            <tr>
              <th colspan="2">
                <strong>
                  {{ key }}
                </strong>
              </th>
            </tr>
            <tr
              v-for="(news, i) in newsGroup"
              :key="`news-group-${key}-news-${i}`"
            >
              <td class="whitespace-nowrap pr-2 align-top">
                {{ news.date }}
              </td>
              <td>
                <Link
                  :href="route('frontend.news.show', {news: news.id})"
                  class="text-red underline hover:no-underline"
                >
                  {{ news.title }}
                </Link>
              </td>
            </tr>
          </table>
        </div>

        <Link
          :href="route('frontend.news.index')"
          class="text-white md:text-red md:underline hover:no-underline"
        >
          <span class="material-symbols-outlined align-bottom text-white md:text-red font-bold">
            chevron_left
          </span>

          {{ __('news.archive.back') }}
        </Link>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    groupedNews: {
      type: Object,
      required: true,
    },
  },
};
</script>

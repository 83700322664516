<template>
  <div class="container">
    <MobileBreadcrumbs
      :pages="[
        {name: __('tell-a-friend.index.title'), href: route('frontend.tell-a-friend.index')},
      ]"
      :current-page="__('tell-a-friend.conditions.title')"
    />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <section class="w-full">
        <div class="flex flex-wrap">
          <div class="bg-white shadow-box rounded p-3 mb-4 md:mb-0 md:shadow-none w-full">
            <DesktopBreadcrumbs
              :pages="[
                {name: __('tell-a-friend.index.title'), href: route('frontend.tell-a-friend.index')},
              ]"
              :current-page="__('tell-a-friend.conditions.title')"
            />

            <h1 class="font-serif text-red mb-4 md:mb-8 font-bold md:text-3xl">
              {{ __('tell-a-friend.conditions.title') }}
            </h1>

            <ul class="list-outside list-disc ml-4">
              <li
                v-for="(condition, i) in conditions"
                :key="`condition-${i}`"
              >
                <span class="text-gray">{{ condition }}</span>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    conditions: {
      type: Object,
      required: true,
    },
  },
};
</script>

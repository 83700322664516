<template>
  <div class="container">
    <MobileBreadcrumbs
      :pages="[
        {name: __('account.overview.title'), href: route('frontend.account')},
        {name: __('account.profile.title'), href: route('frontend.account.profile')},
      ]"
      :current-page="__('account.picture.title')"
    />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4 mb-6">
        <DesktopBreadcrumbs
          :pages="[
            {name: __('account.overview.title'), href: route('frontend.account')},
            {name: __('account.profile.title'), href: route('frontend.account.profile')},
          ]"
          :current-page="__('account.picture.title')"
        />

        <div class="bg-white shadow-box rounded p-3 md:p-0 mb-4 md:shadow-none w-full">
          <h1 class="font-serif text-red mb-2 font-bold md:text-3xl">
            {{ __('account.picture.title') }}
          </h1>

          <form
            class="space-y-4 lg:w-1/2"
            @submit.prevent="form.post(route('frontend.account.picture.store'))"
          >
            <div>
              <div class="mb-8">
                <div class="flex justify-between items-center">
                  <div class="grow">
                    <input
                      id="file-upload"
                      type="file"
                      accept="image/*"
                      class="hidden"
                      @change="selectImage"
                    >
                    <label
                      for="file-upload"
                      class="flex text-red group cursor-pointer"
                    >
                      <span class="material-symbols-outlined">add_a_photo</span>
                      <span class="ml-2 underline group-hover:no-underline">{{ __('account.picture.select') }}</span>
                    </label>
                  </div>

                  <div>
                    <button
                      v-if="form.picture"
                      :disabled="form.processing"
                      class="md:hidden text-red underline hover:no-underline"
                    >
                      {{ __('account.picture.button') }}
                    </button>
                  </div>
                </div>
                <p
                  v-if="form.errors.picture"
                  class="text-red text-xs italic"
                >
                  {{ form.errors.picture }}
                </p>

                <div class="my-4">
                  <img
                    ref="image"
                    :src="pictureUrl"
                    class="max-w-full"
                  >
                </div>
              </div>
            </div>

            <div class="hidden md:flex md:!mt-6">
              <button
                v-if="form.picture"
                class="btn text-sm md:text-base"
                :disabled="form.processing"
              >
                {{ __('account.picture.button') }}

                <span class="material-symbols-outlined text-white font-bold">
                  chevron_right
                </span>
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import Cropper from 'cropperjs';
import {getCanvasResultForCroppedCanvas} from '../../../../../global/js/cropHelpers';

export default {
  props: {
    pictureUrl: {
      type: String,
      required: false,
      default: null,
    },
  },

  emits: ['change'],

  setup() {
    const form = useForm({
      picture: null,
    });

    return {
      form,
    };
  },

  data() {
    return {
      cropper: null,
    };
  },

  mounted() {
    this.resetCropper();
  },

  methods: {
    resetCropper() {
      this.cropper?.destroy();

      this.cropper = new Cropper(this.$refs.image, {
        aspectRatio: 1,
        zoomable: false,
        crop: () => {
          const canvas = getCanvasResultForCroppedCanvas(this.cropper?.getCroppedCanvas({ maxHeight: 250, maxWidth: 250 }));

          if (canvas) {
            this.form.picture = canvas.toDataURL().split(';base64,')[1];
          }
        },
      });
    },

    selectImage(e) {
      if (e.target.files.length !== 1) {
        return;
      }

      const file = e.target.files[0];
      this.$refs.image.src = URL.createObjectURL(file);

      this.resetCropper();
    },
  },
};
</script>

<template>
  <div class="container">
    <MobileBreadcrumbs
      :pages="[
        {name: __('account.overview.title'), href: route('frontend.account')},
      ]"
      :current-page="__('account.mail-settings.breadcrumb-title')"
    />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4 mb-6">
        <DesktopBreadcrumbs
          :pages="[
            {name: __('account.overview.title'), href: route('frontend.account')},
          ]"
          :current-page="__('account.mail-settings.breadcrumb-title')"
        />

        <div class="bg-white shadow-box rounded p-3 md:p-0 mb-4 md:shadow-none w-full">
          <h1 class="font-serif text-red mb-2 font-bold md:text-3xl">
            {{ __('account.mail-settings.title') }}
          </h1>

          <form
            class="form md:mt-4"
            @submit.prevent="form.post(route('frontend.account.notifications.store'))"
          >
            <template
              v-for="type in availableTypes"
              :key="type"
            >
              <div
                v-if="['survey'].includes(type)"
                class="mb-3 md:items-center"
              >
                <label class="w-full text-sm block mb-1 md:font-bold lg:w-2/4">
                  {{ __(`account.mail-settings.types.${type}`) }}
                </label>
                <div class="w-full lg:w-2/4">
                  <select
                    v-model="form[type]"
                  >
                    <option :value="true">
                      {{ __('account.mail-settings.enabled') }}
                    </option>
                    <option :value="false">
                      {{ __('account.mail-settings.disabled') }}
                    </option>
                  </select>
                </div>
              </div>
            </template>

            <div
              v-if="Object.keys(optInAdvertisers).length"
              class="mb-3 md:items-center"
            >
              <label class="w-full text-sm block mb-1 md:font-bold lg:w-2/4">
                {{ __('account.mail-settings.opt-in-advertisers') }}
              </label>
              <div class="w-full lg:w-2/4">
                <div
                  v-for="(name, id) in optInAdvertisers"
                  :key="id"
                  class="flex flex-row space-x-2 items-center"
                >
                  <input
                    v-model="form.member_advertisers"
                    :value="id"
                    type="checkbox"
                  >
                  <span class="">{{ name }}</span>
                </div>
              </div>
            </div>

            <div class="mb-3 md:items-center">
              <label class="w-full text-sm block mb-1 md:font-bold lg:w-2/4">
                {{ __('account.mail-settings.vacation-mode') }}:
              </label>
              <div class="w-full lg:w-2/4">
                <select
                  v-model="form.vacation_enabled"
                >
                  <option :value="true">
                    {{ __('account.mail-settings.enabled') }}
                  </option>
                  <option :value="false">
                    {{ __('account.mail-settings.disabled') }}
                  </option>
                </select>
              </div>
            </div>

            <div v-if="form.vacation_enabled">
              <div class="mb-3 md:items-center">
                <DateField
                  v-model:day="form.vacation_start_day"
                  v-model:month="form.vacation_start_month"
                  v-model:year="form.vacation_start_year"
                  name="vacation_start"
                  :label="__('account.mail-settings.vacation-start')"
                  :day-options="dayOptions"
                  :month-options="monthOptions"
                  :year-options="yearOptions"
                  :errors="form.errors"
                />
              </div>

              <div class="mb-3 md:items-center">
                <DateField
                  v-model:day="form.vacation_end_day"
                  v-model:month="form.vacation_end_month"
                  v-model:year="form.vacation_end_year"
                  name="vacation_end"
                  :label="__('account.mail-settings.vacation-end')"
                  :day-options="dayOptions"
                  :month-options="monthOptions"
                  :year-options="yearOptions"
                  :errors="form.errors"
                />
              </div>
            </div>

            <div class="flex flex-wrap md:items-center">
              <div class="w-full md:w-5/6 lg:w-2/4">
                <button
                  :disabled="form.processing"
                  type="submit"
                  class="btn text-sm md:text-base"
                >
                  {{ __('account.mail-settings.form.button') }}

                  <span class="material-symbols-outlined text-white font-bold">
                    chevron_right
                  </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import DateField from '../../../Components/Form/DateField.vue';

export default {
  components: {
    DateField,
  },

  props: {
    availableTypes: {
      type: Array,
      required: true,
    },

    singleTypes: {
      type: Array,
      required: true,
    },

    groupedTypes: {
      type: Object,
      required: true,
    },

    memberTypes: {
      type: Array,
      required: true,
    },

    dayOptions: {
      type: Object,
      required: true,
    },

    monthOptions: {
      type: Object,
      required: true,
    },

    yearOptions: {
      type: Object,
      required: true,
    },

    vacationStart: {
      type: Object,
      required: false,
      default: null,
    },

    vacationEnd: {
      type: Object,
      required: false,
      default: null,
    },

    optInAdvertisers: {
      type: Object,
      required: false,
      default: null,
    },

    memberAdvertisers: {
      type: Array,
      required: false,
      default: null,
    },
  },

  setup(props) {
    const formData = {
      vacation_enabled: !!(props.vacationStart && props.vacationEnd),
      vacation_start_day: props.vacationStart ? props.vacationStart.day : null,
      vacation_start_month: props.vacationStart ? props.vacationStart.month : null,
      vacation_start_year: props.vacationStart ? props.vacationStart.year : null,
      vacation_end_day: props.vacationEnd ? props.vacationEnd.day : null,
      vacation_end_month: props.vacationEnd ? props.vacationEnd.month : null,
      vacation_end_year: props.vacationEnd ? props.vacationEnd.year : null,
      member_advertisers: props.memberAdvertisers ? props.memberAdvertisers : null,
    };
    props.availableTypes.forEach(type => formData[type] = props.memberTypes.includes(type));

    const form = useForm(formData);

    return {form};
  },
};
</script>

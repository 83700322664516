<template>
  <div class="container">
    <MobileBreadcrumbs
      :pages="[
        {name: item.category.name, href: route('frontend.faq')},
      ]"
      :current-page="item.question"
    />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4 mb-6">
        <div class="flex flex-wrap">
          <div class="bg-white shadow-box rounded p-3 md:p-0 mb-4 md:shadow-none w-full">
            <DesktopBreadcrumbs
              :pages="[
                {name: item.category.name, href: route('frontend.faq')},
              ]"
              :current-page="item.question"
            />

            <h1 class="font-serif text-red mb-2 font-bold md:text-3xl">
              {{ item.question }}
            </h1>

            <!-- eslint-disable vue/no-v-html -->
            <div
              class="content"
              v-html="item.answer"
            />
            <!-- eslint-enable -->
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div class="container">
    <MobileBreadcrumbs
      :pages="[
        {name: __('payout.index.title'), href: route('frontend.payout')},
      ]"
      :current-page="__('payout.successful.title')"
    />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4 mb-6">
        <DesktopBreadcrumbs
          :pages="[
            {name: __('payout.index.title'), href: route('frontend.payout')},
          ]"
          :current-page="__('payout.successful.title')"
        />

        <h1 class="text-white font-bold font-serif mb-4 text-center md:text-red md:text-3xl md:text-left">
          {{ __('payout.successful.title') }}
        </h1>

        <div class="bg-white shadow-box rounded p-3 mb-2 md:mb-0  flex flex-wrap">
          {{ __('payout.successful.text') }}
        </div>
      </section>
    </div>
  </div>
</template>

<template>
  <footer class="pt-6 pb-4 md:mt-12">
    <ul class="text-center text-sm list-inside leading-6">
      <li class="inline-block px-1">
        <Link
          :href="route('frontend.terms')"
          class="text-white underline hover:no-underline"
        >
          {{ __('footer.terms') }}
        </Link>
      </li>

      <li class="inline-block px-1 text-white">
        |
      </li>

      <li class="inline-block px-1">
        <Link
          :href="route('frontend.privacy-policy')"
          class="text-white underline hover:no-underline"
        >
          {{ __('footer.privacy-policy') }}
        </Link>
      </li>

      <li class="inline-block px-1 text-white">
        |
      </li>

      <li class="inline-block px-1">
        <Link
          :href="route('frontend.cookie-policy')"
          class="text-white underline hover:no-underline"
        >
          {{ __('footer.cookie-policy') }}
        </Link>
      </li>

      <li class="inline-block px-1 text-white">
        |
      </li>

      <li class="inline-block px-1">
        <Link
          :href="route('frontend.faq')"
          class="text-white underline hover:no-underline"
        >
          {{ __('footer.faq') }}
        </Link>
      </li>

      <li class="inline-block px-1 text-white">
        |
      </li>

      <li class="inline-block px-1">
        <Link
          :href="route('frontend.contact')"
          class="text-white underline hover:no-underline"
        >
          {{ __('footer.contact') }}
        </Link>
      </li>

      <li
        v-if="$page.props.auth?.member"
        class="inline-block px-1 text-white"
      >
        |
      </li>

      <li
        v-if="$page.props.auth?.member"
        class="inline-block px-1"
      >
        <Link
          :href="route('frontend.logout')"
          method="post"
          as="button"
          class="text-white underline hover:no-underline"
        >
          {{ __('general.logout') }}
        </Link>
      </li>
    </ul>
  </footer>
</template>

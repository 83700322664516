<template>
  <div class="container">
    <MobileBreadcrumbs :current-page="__('account.overview.title')" />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4 mb-6">
        <DesktopBreadcrumbs :current-page="__('account.overview.title')" />

        <h1 class="text-white font-bold font-serif mb-4 text-center md:text-red md:text-3xl md:text-left">
          {{ __('account.overview.title') }}
        </h1>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-8">
          <div class="bg-white shadow-box rounded p-3 mb-2 md:mb-0  flex flex-wrap">
            <div>
              <span class="text-red font-serif font-bold mb-2 block w-full">
                {{ __('account.overview.personal-settings.title') }}
              </span>
              <span>
                {{ __('account.overview.personal-settings.text') }}
              </span>
            </div>

            <div class="flex self-end w-full">
              <Link
                :href="route('frontend.account.profile')"
                class="btn mt-3 text-sm md:text-base"
              >
                {{ __('account.overview.personal-settings.button') }}

                <span class="material-symbols-outlined text-white font-bold">
                  chevron_right
                </span>
              </Link>
            </div>
          </div>

          <div class="bg-white shadow-box rounded p-3 mb-2 md:mb-0  flex flex-wrap">
            <div>
              <span class="text-red font-serif font-bold mb-2 block w-full">
                {{ __('account.overview.mail-settings.title') }}
              </span>
              <span>
                {{ __('account.overview.mail-settings.text') }}
              </span>
            </div>

            <div class="flex self-end w-full">
              <Link
                :href="route('frontend.account.notifications')"
                class="btn mt-3 text-sm md:text-base"
              >
                {{ __('account.overview.mail-settings.button') }}

                <span class="material-symbols-outlined text-white font-bold">
                  chevron_right
                </span>
              </Link>
            </div>
          </div>

          <div class="bg-white shadow-box rounded p-3 mb-2 md:mb-0  flex flex-wrap">
            <div>
              <span class="text-red font-serif font-bold mb-2 block w-full">
                {{ __('account.overview.payout-settings.title') }}
              </span>
              <span>
                {{ __('account.overview.payout-settings.text') }}
              </span>
            </div>

            <div class="flex self-end w-full">
              <Link
                :href="route('frontend.account.payout')"
                class="btn mt-3 text-sm md:text-base"
              >
                {{ __('account.overview.payout-settings.button') }}

                <span class="material-symbols-outlined text-white font-bold">
                  chevron_right
                </span>
              </Link>
            </div>
          </div>

          <div class="bg-white shadow-box rounded mb-2 md:mb-0 p-3 md:p-4 flex flex-wrap">
            <div>
              <span class="text-red font-serif font-bold mb-2 block w-full">
                {{ __('account.overview.security-settings.title') }}
              </span>
              <span>
                {{ __('account.overview.security-settings.text') }}
              </span>
            </div>

            <div class="flex self-end w-full">
              <Link
                :href="route('frontend.account.change-password')"
                class="btn mt-3 text-sm md:text-base"
              >
                {{ __('account.overview.security-settings.button') }}

                <span class="material-symbols-outlined text-white font-bold">
                  chevron_right
                </span>
              </Link>
            </div>
          </div>

          <div class="bg-white shadow-box rounded mb-2 md:mb-0 p-3 md:p-4 flex flex-wrap">
            <div>
              <span class="text-red font-serif font-bold mb-2 block w-full">
                {{ __('account.overview.profile-questions.title') }}
              </span>
              <span>
                {{ __('account.overview.profile-questions.text') }}
              </span>
            </div>

            <div class="flex self-end w-full">
              <Link
                :href="route('frontend.profile-questions.index')"
                class="btn mt-3 text-sm md:text-base"
              >
                {{ __('account.overview.profile-questions.button') }}

                <span class="material-symbols-outlined text-white font-bold">
                  chevron_right
                </span>
              </Link>
            </div>
          </div>

          <div class="bg-white shadow-box rounded mb-2 md:mb-0 p-3 md:p-4 flex flex-wrap">
            <div>
              <span class="text-red font-serif font-bold mb-2 block w-full">
                {{ __('account.overview.delete-account-settings.title') }}
              </span>
              <span>
                {{ __('account.overview.delete-account-settings.text') }}
              </span>
            </div>

            <div class="flex self-end w-full">
              <Link
                :href="route('frontend.account.delete')"
                class="btn mt-3 text-sm md:text-base"
              >
                {{ __('account.overview.delete-account-settings.button') }}

                <span class="material-symbols-outlined text-white font-bold">
                  chevron_right
                </span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<template>
  <div class="container">
    <MobileBreadcrumbs :current-page="__('contact.title')" />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4 mb-6">
        <div class="flex flex-wrap">
          <div class="bg-white shadow-box rounded p-3 md:p-0 mb-4 md:shadow-none w-full">
            <DesktopBreadcrumbs :current-page="__('contact.title')" />

            <h1 class="font-serif text-red mb-2 font-bold md:text-3xl">
              {{ __('contact.title') }}
            </h1>

            <i18n-t
              v-if="form.wasSuccessful"
              tag="div"
              keypath="contact.success-message"
              class="my-4 whitespace-pre-line"
            >
              <template
                v-if="member"
                #name
              >
                {{ member.first_name }} {{ member.last_name }}
              </template>
              <template
                v-else
                #name
              />
              <template #faqLink>
                <Link
                  :href="route('frontend.faq')"
                  class="text-red underline hover:no-underline"
                >
                  {{ __('contact.success-message-faq') }}
                </Link>
              </template>
            </i18n-t>
            <div v-else>
              <p class="mb-4">
                {{ __('contact.text') }}
              </p>
              <form
                class="form lg:w-3/5"
                @submit.prevent="form.post(route('frontend.contact.store'), {
                  onSuccess: () => form.reset(),
                })"
              >
                <div class="flex flex-wrap items-center mb-3">
                  <label class="w-full text-sm block mb-1 md:mb-0 md:w-1/3 md:font-bold">
                    {{ __('general.category') }}:
                    <span class="text-red">
                      &#42;
                    </span>
                  </label>
                  <div class="w-full md:w-2/3">
                    <select v-model="form.category">
                      <option
                        v-for="(category, value) in $tm('contact.categories')"
                        :key="value"
                        :value="$rt(category)"
                      >
                        {{ $rt(category) }}
                      </option>
                    </select>
                  </div>
                  <p
                    v-if="form.errors.category"
                    class="text-red text-xs italic"
                  >
                    {{ form.errors.category }}
                  </p>
                </div>

                <div class="flex flex-wrap items-center mb-3">
                  <label class="w-full text-sm block mb-1 md:mb-0 md:w-1/3 md:font-bold">
                    {{ __('general.emailaddress') }}:
                    <span class="text-red">
                      &#42;
                    </span>
                  </label>
                  <div class="w-full md:w-2/3">
                    <input
                      v-model="form.email"
                      type="email"
                      autocomplete="email"
                      required
                    >
                  </div>
                  <p
                    v-if="form.errors.email"
                    class="text-red text-xs italic"
                  >
                    {{ form.errors.email }}
                  </p>
                </div>

                <div class="flex flex-wrap items-center mb-3">
                  <label class="w-full text-sm block mb-1 md:mb-0 md:w-1/3 md:font-bold">
                    {{ __('general.full_name') }}:
                    <span class="text-red">
                      &#42;
                    </span>
                  </label>
                  <div class="w-full md:w-2/3">
                    <input
                      v-model="form.name"
                      type="text"
                      autocomplete="name"
                      required
                    >
                  </div>
                  <p
                    v-if="form.errors.name"
                    class="text-red text-xs italic"
                  >
                    {{ form.errors.name }}
                  </p>
                </div>

                <div class="flex flex-wrap items-center mb-3">
                  <label class="w-full text-sm block mb-1 md:mb-0 md:w-1/3 md:font-bold">
                    {{ __('general.subject') }}:
                    <span class="text-red">
                      &#42;
                    </span>
                  </label>
                  <div class="w-full md:w-2/3">
                    <input
                      v-model="form.subject"
                      type="text"
                      required
                    >
                  </div>
                  <p
                    v-if="form.errors.subject"
                    class="text-red text-xs italic"
                  >
                    {{ form.errors.subject }}
                  </p>
                </div>

                <div class="flex flex-wrap items-center mb-3">
                  <label class="w-full text-sm block mb-1 md:mb-0 md:w-1/3 md:font-bold">
                    {{ __('general.attachment') }}:
                  </label>
                  <div class="w-full md:w-2/3">
                    <input
                      type="file"
                      @input="setAttachment($event)"
                    >
                  </div>
                  <p
                    v-if="attachmentExceedsSize"
                    class="text-red text-xs italic"
                  >
                    {{ __('general.max_file_size', {'limit': attachmentLimit + 'MB'}) }}
                  </p>
                  <p
                    v-if="form.errors.attachment"
                    class="text-red text-xs italic"
                  >
                    {{ form.errors.attachment }}
                  </p>
                </div>

                <div class="flex flex-wrap mb-3 ">
                  <label class="w-full text-sm block mb-1 md:mb-0 md:mt-2 md:w-1/3 md:font-bold">
                    {{ __('general.message') }}:
                    <span class="text-red">
                      &#42;
                    </span>
                  </label>
                  <div class="w-full md:w-2/3">
                    <textarea
                      v-model="form.message"
                      rows="4"
                      required
                    />
                  </div>
                  <p
                    v-if="form.errors.message"
                    class="text-red text-xs italic"
                  >
                    {{ form.errors.message }}
                  </p>
                </div>

                <div class="flex flex-wrap">
                  <label class="w-full text-sm block mb-1 md:mb-0 md:w-1/3 md:font-bold" />

                  <div class="w-full md:w-2/3">
                    <button
                      type="submit"
                      class="btn text-sm md:text-base"
                      :disabled="form.processing"
                    >
                      {{ __('contact.button') }}:

                      <span class="material-symbols-outlined text-white font-bold">
                        chevron_right
                      </span>
                    </button>

                    <span class="text-xs block mt-3 text-center md:text-left">
                      <i18n-t
                        keypath="form.required-fields"
                        scope="global"
                      >
                        <template #asterisk>
                          <span class="text-red">
                            &#42;
                          </span>
                        </template>
                      </i18n-t>
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';
import {useForm} from '@inertiajs/vue3';

export default {
  props: {
    attachmentLimit: {
      type: Number,
      required: true,
    },

    member: {
      type: Object,
      required: false,
      default: null,
    },
  },

  setup() {
    const form = useForm({
      category: '',
      email: '',
      name: '',
      subject: '',
      attachment: null,
      message: '',
    });

    return {
      form,
      attachmentExceedsSize: ref(false),
    };
  },

  methods: {
    setAttachment(e) {
      const field = e.target.files[0];
      this.form.attachment = field;

      const fileLimitBytes = this.attachmentLimit * 1000000;
      this.attachmentExceedsSize = false;
      if (field.size > fileLimitBytes) {
        this.attachmentExceedsSize = true;
        this.form.attachment = null;
      }
    },
  },
};
</script>

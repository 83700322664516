<template>
  <div>
    <slot
      name="modal-button"
      :openModal="openModal"
    />

    <HeadlessTransitionRoot
      appear
      :show="isOpen"
      as="div"
    >
      <HeadlessDialog
        as="div"
        @close="closeModal"
      >
        <div class="fixed inset-0 z-50 overflow-y-auto">
          <HeadlessTransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <HeadlessDialogOverlay class="fixed inset-0 bg-red bg-opacity-80 transition-opacity" />
          </HeadlessTransitionChild>

          <HeadlessTransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div class="w-screen h-screen transform">
              <div class="absolute top-1 md:top-4 right-4">
                <button @click="closeModal">
                  <span class="material-symbols-outlined text-white text-2xl md:text-5xl">
                    cancel
                  </span>
                </button>
              </div>

              <slot
                name="modal-content"
                :closeModal="closeModal"
              />
            </div>
          </HeadlessTransitionChild>
        </div>
      </HeadlessDialog>
    </HeadlessTransitionRoot>
  </div>
</template>

<script>
import {ref} from 'vue';
import {Dialog as HeadlessDialog, DialogOverlay as HeadlessDialogOverlay, TransitionChild as HeadlessTransitionChild, TransitionRoot as HeadlessTransitionRoot} from '@headlessui/vue';

export default {
  components: {
    HeadlessTransitionRoot, HeadlessTransitionChild, HeadlessDialog, HeadlessDialogOverlay,
  },

  props: {
    open: {
      type: Boolean,
      required: false,
    },
  },

  setup(props) {
    const isOpen = ref(props.open);

    return {
      isOpen,

      closeModal() {
        isOpen.value = false;
      },

      openModal() {
        isOpen.value = true;
      },
    };
  },
};
</script>

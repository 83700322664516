<template>
  <div class="container">
    <MobileBreadcrumbs :current-page="__('content.earn-cashback.title')" />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4">
        <DesktopBreadcrumbs :current-page="__('content.earn-cashback.title')" />

        <h1 class="text-white font-bold font-serif mb-4 text-center md:text-red md:text-3xl md:text-left">
          {{ __('content.earn-cashback.title') }}
        </h1>

        <div class="grid grid-cols-2 lg:grid-cols-5 gap-3 mb-8">
          <Link
            :href="route('frontend.puzzles')"
            class="shadow-box rounded px-2 pt-8 pb-4 flex flex-wrap content-between bg-white"
          >
            <div class="mx-auto mb-4 text-center">
              <span class="block w-full">
                <img
                  src="/geldrace/images/puzzelen.png"
                  class="ml-2 mb-4"
                >
              </span>
              <span class="font-bold block w-full">
                {{ __('content.earn-cashback.puzzle.title') }}
              </span>
            </div>

            <div class="btn md:!w-[auto] text-sm md:text-base md:inline-flex md:px-4 mx-auto">
              {{ __('content.earn-cashback.puzzle.button') }}

              <span class="material-symbols-outlined text-white font-bold">
                chevron_right
              </span>
            </div>
          </Link>

          <Link
            :href="route('frontend.free-actions.index')"
            class="shadow-box rounded px-2 pt-8 pb-4 flex flex-wrap content-between bg-white"
          >
            <div class="mx-auto mb-4 text-center">
              <span class="block w-full">
                <img
                  src="/geldrace/images/gratis.png"
                  class="mx-auto mb-4"
                >
              </span>
              <span class="font-bold block w-full">
                {{ __('content.earn-cashback.free.title') }}
              </span>
            </div>

            <div class="btn md:!w-[auto] text-sm md:text-base md:inline-flex md:px-4 mx-auto">
              {{ __('content.earn-cashback.free.button') }}

              <span class="material-symbols-outlined text-white font-bold">
                chevron_right
              </span>
            </div>
          </Link>

          <Link
            :href="route('frontend.click-actions.index')"
            class="shadow-box rounded px-2 pt-8 pb-4 flex flex-wrap content-between bg-white"
          >
            <div class="mx-auto mb-4 text-center">
              <span class="block w-full">
                <img
                  src="/geldrace/images/klikken.png"
                  class="mx-auto mb-4"
                >
              </span>
              <span class="font-bold block w-full">
                {{ __('content.earn-cashback.click.title') }}
              </span>
            </div>

            <div class="btn md:!w-[auto] text-sm md:text-base md:inline-flex md:px-4 mx-auto">
              {{ __('content.earn-cashback.click.button') }}

              <span class="material-symbols-outlined text-white font-bold">
                chevron_right
              </span>
            </div>
          </Link>
        </div>
      </section>
    </div>
  </div>
</template>

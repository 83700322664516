<template>
  <div class="container">
    <MobileBreadcrumbs
      :pages="[
        {name: 'Webshops', href: route('frontend.webshop-category.index')},
        {name: webshop.name, href: route('frontend.webshop.show', {webshop: webshop.slug})},
        {name: __('webshop-reviews.index.title'), href: route('frontend.webshop-reviews.index', {webshop: webshop.slug})},
      ]"
      :current-page="__('webshop-reviews.create.breadcrumb')"
    />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4 mb-6">
        <div class="flex flex-wrap">
          <div class="bg-white shadow-box rounded p-3 md:p-0 mb-4 md:shadow-none w-full">
            <DesktopBreadcrumbs
              :pages="[
                {name: 'Webshops', href: route('frontend.webshop-category.index')},
                {name: webshop.name, href: route('frontend.webshop.show', {webshop: webshop.slug})},
                {name: __('webshop-reviews.index.title'), href: route('frontend.webshop-reviews.index', {webshop: webshop.slug})},
              ]"
              :current-page="__('webshop-reviews.create.breadcrumb')"
            />

            <h1 class="font-serif text-red mb-2 font-bold md:text-3xl">
              {{ __('webshop-reviews.create.title', {webshop: webshop.name}) }}
            </h1>

            <div>
              <form
                class="form lg:w-3/5"
                @submit.prevent="form.post(route('frontend.webshop-reviews.store', {webshop: webshop.slug}), {
                  onSuccess: () => form.reset(),
                })"
              >
                <div class="flex flex-wrap items-center mb-3">
                  <label class="w-full text-sm block mb-1 md:mb-0 md:w-1/3 md:font-bold">
                    {{ __('webshop-reviews.create.form.general-score') }}:
                  </label>
                  <div class="w-full md:w-2/3">
                    <select
                      v-model="form.general_score"
                      required
                    >
                      <option
                        v-for="i in 5"
                        :key="i"
                        :value="i"
                      >
                        {{ i }}
                      </option>
                    </select>
                  </div>
                  <p
                    v-if="form.errors.general_score"
                    class="text-red text-xs italic"
                  >
                    {{ form.errors.general_score }}
                  </p>
                </div>

                <div class="flex flex-wrap mb-3 ">
                  <label class="w-full text-sm block mb-1 md:mb-0 md:mt-2 md:w-1/3 md:font-bold">
                    {{ __('webshop-reviews.create.form.text') }}:
                  </label>
                  <div class="w-full md:w-2/3">
                    <textarea
                      v-model="form.text"
                      rows="4"
                      required
                    />
                  </div>
                  <p
                    v-if="form.errors.text"
                    class="text-red text-xs italic"
                  >
                    {{ form.errors.text }}
                  </p>
                </div>

                <div class="flex flex-wrap">
                  <label class="w-full text-sm block mb-1 md:mb-0 md:w-1/3 md:font-bold" />

                  <div class="w-full md:w-2/3">
                    <button
                      type="submit"
                      class="btn text-sm md:text-base"
                      :disabled="form.processing"
                    >
                      {{ __('webshop-reviews.create.form.button') }}

                      <span class="material-symbols-outlined text-white font-bold">
                        chevron_right
                      </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
import {defineProps} from 'vue';
import {useForm} from '@inertiajs/vue3';

const props = defineProps({
  webshop: {
    type: Object,
    required: true,
  },

  cashback: {
    type: Object,
    required: true,
  },
});

const form = useForm({
  cashback_id: props.cashback.id,
  general_score: '',
  text: '',
});
</script>

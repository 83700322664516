<template>
  <div class="container">
    <MobileBreadcrumbs :current-page="__('puzzle.index.title')" />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4">
        <DesktopBreadcrumbs :current-page="__('puzzle.index.title')" />

        <h1 class="text-white font-bold font-serif mb-4 text-center md:text-red md:text-3xl md:text-left">
          {{ __('puzzle.index.title') }}
        </h1>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-8">
          <div
            v-for="puzzle of puzzles"
            :key="`puzzle-${puzzle.id}`"
            class="bg-white shadow-box p-3 md:p-4 rounded md:h-full mb-2 md:mb-0"
          >
            <span class="text-red font-serif font-bold mb-4 block">
              {{ puzzle.title }}
            </span>
            <img
              :src="puzzle.media?.url"
              :alt="puzzle.title"
              class="mb-4"
            >
            <span class="font-serif font-bold">
              {{ puzzle.question }}
            </span>
            <p>
              <i18n-t
                keypath="puzzle.index.text"
                scope="global"
              >
                <template #reward>
                  <strong>{{ puzzle.reward }}</strong>
                </template>
              </i18n-t>
            </p>

            <p
              v-if="puzzle.has_member_answer"
              class="block text-red italic"
            >
              <span v-if="puzzle.member_answer_is_correct">
                {{ __('puzzle.index.answered.correct') }}
              </span>
              <span v-else>
                {{ __('puzzle.index.answered.wrong') }}
              </span>
            </p>
            <form
              v-else
              class="form"
            >
              <select v-model="puzzleAnswers[puzzle.id]">
                <option
                  selected
                  disabled
                  :value="null"
                >
                  {{ __('puzzle.index.default-option') }}
                </option>
                <option
                  v-for="answer of puzzle.answers"
                  :key="`puzzle-${puzzle.id}-answer-${answer.id}`"
                  :value="answer.id"
                >
                  {{ answer.answer }}
                </option>
              </select>

              <div class="px-0.5">
                <button
                  type="button"
                  class="btn mt-2 text-sm"
                  @click="submitPuzzleAnswer(puzzle.id)"
                >
                  {{ __('puzzle.index.button') }}

                  <span class="material-symbols-outlined text-white font-bold">
                    chevron_right
                  </span>
                </button>
              </div>
            </form>
          </div>

          <div class="bg-white shadow-box p-3 md:p-4 rounded md:h-full">
            <span class="text-red font-serif font-bold mb-2 block">
              {{ __('puzzle.index.poll.title') }}
            </span>

            <form class="form">
              <div
                v-for="puzzle of puzzles"
                :key="`puzzle-poll-${puzzle.id}`"
              >
                <span class="font-serif font-bold block mb-1 mt-2">
                  {{ __('puzzle.index.poll.poll-title', {title: puzzle.title}) }}:
                </span>

                <div v-if="puzzle.has_poll_answer">
                  <div
                    v-for="difficulty of pollDifficulties"
                    :key="`puzzle-poll-${puzzle.id}-answer-${difficulty}`"
                    class="flex flex-wrap"
                  >
                    <div class="w-3/6">
                      {{ $tm('puzzle.index.poll.answers')[difficulty] }}:
                    </div>
                    <div class="w-3/6 font-bold">
                      {{ puzzle.poll_results[difficulty] }}%
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div
                    v-for="difficulty of pollDifficulties"
                    :key="`puzzle-poll-${puzzle.id}-answer-${difficulty}`"
                    class="flex items-center"
                  >
                    <label>
                      <input
                        v-model="pollAnswers[puzzle.id]"
                        type="radio"
                        :name="`puzzle[${puzzle.id}]`"
                        :value="difficulty"
                      >
                      <span class="inline-block ml-2">
                        {{ $tm('puzzle.index.poll.answers')[difficulty] }}
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <button
                v-if="puzzles.find((puzzle) => !puzzle.has_poll_answer)"
                type="button"
                class="btn mt-3 text-sm"
                @click="submitPollAnswers"
              >
                {{ __('puzzle.index.poll.button') }}

                <span class="material-symbols-outlined text-white font-bold">
                  chevron_right
                </span>
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    puzzles: {
      type: Array,
      required: true,
    },

    pollDifficulties: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    const puzzleAnswers = {};
    const pollAnswers = {};

    for (const puzzle of props.puzzles) {
      puzzleAnswers[puzzle.id] = null;
    }

    return {
      puzzleAnswers,
      pollAnswers,
    };
  },

  methods: {
    submitPuzzleAnswer(puzzleId) {
      if (!(puzzleId in this.puzzleAnswers) || this.puzzleAnswers[puzzleId] === null) {
        return;
      }

      this.$inertia.post(this.route('frontend.puzzles.store-puzzle-answer', {puzzle: puzzleId}), {
        answer: this.puzzleAnswers[puzzleId],
      });
    },

    submitPollAnswers() {
      if (Object.keys(this.pollAnswers).length === 0) {
        return 0;
      }

      this.$inertia.post(this.route('frontend.puzzles.store-poll-answers'), {
        answers: this.pollAnswers,
      });

      this.pollAnswers = {};
    },
  },
};
</script>

<template>
  <TransitionRoot
    :show="show"
    enter="transition-opacity duration-150"
    enter-from="opacity-0"
    enter-to="opacity-100"
    leave="transition-opacity duration-300"
    leave-from="opacity-100"
    leave-to="opacity-0"
  >
    <div
      v-if="$page.props.flash.success && show"
      class="container relative cursor-pointer z-50"
      @click="show = false"
    >
      <div class="absolute top-2 right-6 border-2 border-yellow shadow-double bg-red flex justify-center items-center rounded-lg p-2 text-white font-bold max-w-[80%]">
        <span class="material-symbols-outlined text-green">
          task_alt
        </span>
        <div class="ml-2">
          {{ $page.props.flash.success.message ?? $page.props.flash.success }}
        </div>
      </div>
    </div>

    <div
      v-if="$page.props.flash.error && show"
      class="container relative cursor-pointer z-50"
      @click="show = false"
    >
      <div class="absolute top-2 right-6 border-2 border-yellow shadow-double bg-red flex justify-center items-center rounded-lg p-2 text-white font-bold max-w-[80%]">
        <span class="material-symbols-outlined text-yellow">
          error
        </span>
        <div class="ml-2">
          {{ $page.props.flash.error.message ?? $page.props.flash.error }}
        </div>
      </div>
    </div>
  </TransitionRoot>
</template>

<script>
import { TransitionRoot } from '@headlessui/vue';

export default {
  components: {
    TransitionRoot,
  },

  data() {
    return {
      show: true,
    };
  },

  watch: {
    '$page.props.flash': {
      handler() {
        this.show = true;
      },

      deep: true,
    },
  },
};
</script>

<template>
  <template v-if="show && $page.props.notificationBar">
    <Component
      :is="$page.props.notificationBar.url ? 'a' : 'div'"
      :href="$page.props.notificationBar.url"
      :style="{
        backgroundColor: $page.props.notificationBar.background_color,
      }"
      target="_blank"
      class="group block text-white"
    >
      <div class="container relative md:static">
        <div class="lg:flex lg:justify-center lg:items-center text-center py-3">
          <div class="px-4 lg:ml-auto lg:max-w-4xl lg:px-0">
            <div :class="{ 'underline group-hover:no-underline': $page.props.notificationBar.url }">
              {{ $page.props.notificationBar.text }}
            </div>
          </div>
          <div class="absolute top-1.5 right-1.5 lg:static lg:ml-auto">
            <button
              class="flex"
              @click.prevent="hideNotificationBar"
            >
              <span class="material-symbols-outlined text-xl lg:text-2xl">
                cancel
              </span>
            </button>
          </div>
        </div>
      </div>
    </Component>
  </template>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      show: this.$page.props.notificationBar,
    };
  },

  methods: {
    hideNotificationBar() {
      this.show = false;

      axios.post(this.route('frontend.api.hide-notification-bar'));
    },
  },
};
</script>

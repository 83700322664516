<template>
  <div class="container">
    <MobileBreadcrumbs
      :pages="[
        {name: __('account.overview.title'), href: route('frontend.account')},
      ]"
      :current-page="__('account.delete.title')"
    />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4 mb-6">
        <div class="flex flex-wrap">
          <div class="bg-white shadow-box rounded p-3 md:p-0 mb-4 md:shadow-none w-full">
            <DesktopBreadcrumbs
              :pages="[
                {name: __('account.overview.title'), href: route('frontend.account')},
              ]"
              :current-page="__('account.delete.title')"
            />

            <h1 class="font-serif text-red mb-2 font-bold md:text-3xl">
              {{ __('account.delete.title') }}
            </h1>
            <p class="mb-4">
              {{ __('account.delete.text', {amount: $page.props.auth.member.balance}) }}
            </p>

            <form
              class="form lg:w-4/5"
              @submit.prevent="$refs.recaptchaForm.FormSubmit()"
            >
              <div>
                <label class="w-full text-sm block mb-1">
                  {{ __('account.delete.reason') }}:
                </label>

                <textarea
                  v-model="form.reason"
                  rows="4"
                />
              </div>

              <div class="flex flex-wrap mb-3 items-center">
                <label class="w-full text-sm block mb-1 md:w-1/3 pr-3">
                  {{ __('general.password_confirmation') }}:
                </label>

                <Password v-model="form.password" />

                <p
                  v-if="form.errors.password"
                  class="text-red text-xs italic"
                >
                  {{ form.errors.password }}
                </p>
              </div>

              <div class="flex flex-wrap mb-3 items-center">
                <label class="w-full text-sm block mb-1 md:w-1/3 pr-3" />

                <HiddenRecaptcha
                  ref="recaptchaForm"
                  :form="form"
                  @success="recaptchaSuccess"
                />

                <p
                  v-if="form.errors.recaptcha"
                  class="text-red text-xs italic"
                >
                  {{ form.errors.recaptcha }}
                </p>
              </div>

              <div class="flex flex-wrap">
                <label class="w-full text-sm block mb-1 md:mb-0 md:w-1/3 md:font-bold" />
                <div class="w-full md:w-2/3">
                  <button
                    :disabled="form.processing"
                    type="submit"
                    class="btn text-sm md:text-base"
                  >
                    {{ __('account.delete.button') }}

                    <span class="material-symbols-outlined text-white font-bold">
                      chevron_right
                    </span>
                  </button>

                  <span class="block mt-3 text-center md:text-left text-red font-bold text-sm">
                    {{ __('account.delete.warning') }}
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import Password from '../../../Components/Form/Password.vue';
import HiddenRecaptcha from '../../../Components/Form/HiddenRecaptcha.vue';

export default {
  components: {
    Password,
    HiddenRecaptcha,
  },

  props: {
    recaptchaSiteKey: {
      type: String,
      required: true,
    },
  },

  setup() {
    const form = useForm({
      reason: null,
      password: '',
      recaptcha: '',
    });

    return {form};
  },

  methods: {
    recaptchaSuccess: function (mutatedForm) {
      mutatedForm.post(this.route('frontend.account.delete.store'), {
        onSuccess: () => {
          this.form.reset('password');
        },
      });
    },

  },
};
</script>

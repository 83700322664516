<template>
  <div class="container">
    <MobileBreadcrumbs
      :pages="[
        { name: __('profile-questions.index.title'), href: route('frontend.profile-questions.index') },
      ]"
      :current-page="__('profile-questions.open.title')"
    />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4 mb-6">
        <DesktopBreadcrumbs
          :pages="[
            { name: __('profile-questions.index.title'), href: route('frontend.profile-questions.index') },
          ]"
          :current-page="__('profile-questions.open.title')"
        />

        <div class="bg-white shadow-box rounded p-3 md:p-0 mb-4 md:shadow-none w-full">
          <h1 class="font-serif text-red mb-2 font-bold md:text-3xl">
            {{ __('profile-questions.open.title') }}
          </h1>

          <section class="flex flex-col mb-6 space-y-4">
            <div
              v-if="questions.length === 0"
              class="p-4"
            >
              <p class="text-sm md:text-base text-center py-2">
                {{ __('profile-questions.open.no-data') }}
              </p>
            </div>
            <div v-else-if="activeQuestion">
              <ProfileQuestionsFactory
                v-model="memberAnswers[activeQuestion.id]"
                :question="activeQuestion"
                @validation-error="validationError(activeQuestion.id, $event)"
              />

              <div
                v-show="validationErrorMessages[activeQuestion.id]"
                class="mt-2 text-red text-sm"
              >
                *{{ validationErrorMessages[activeQuestion.id] }}
              </div>

              <div class="flex items-center justify-between mt-4">
                <div>
                  <div
                    v-show="activeQuestionKey > 0"
                    class="text-xs text-white md:text-red hover:underline cursor-pointer"
                    @click="activeQuestionKey--"
                  >
                    {{ __('profile-questions.questions.previous') }}
                  </div>
                </div>
                <div>
                  <button
                    class="btn text-sm md:text-base !px-2"
                    :class="memberAnswers[activeQuestion.id].length === 0 ? 'opacity-40' : ''"
                    :disabled="memberAnswers[activeQuestion.id].length === 0 || updating.includes(activeQuestion.id) || validationErrorMessages[activeQuestion.id]"
                    :loading="updating.includes(activeQuestion.id)"
                    @click="updateQuestion(activeQuestion.id, memberAnswers[activeQuestion.id])"
                  >
                    {{ __('profile-questions.questions.next') }}
                    <span
                      v-if="updating.includes(activeQuestion.id)"
                      class="material-symbols-outlined text-white animate-spin font-bold"
                    >
                      autorenew
                    </span>
                    <span
                      v-else
                      class="material-symbols-outlined text-white font-bold"
                    >
                      chevron_right
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div
              v-else
              class="p-4"
            >
              <p class="text-sm md:text-base text-center py-2">
                {{ __('profile-questions.open.done') }}
              </p>

              <div
                class="text-red hover:underline cursor-pointer"
                @click="activeQuestionKey--"
              >
                {{ __('profile-questions.questions.previous') }}
              </div>
            </div>
          </section>
        </div>

        <section
          v-if="questions.length > 0"
          class="flex flex-col mb-6"
        >
          <div class="w-full bg-gray-200 border border-white">
            <div
              class="bg-red text-2xs text-center py-1.5 leading-none"
              :class="progressPercentage > 0 ? 'text-white' : 'text-gray-900'"
              :style="`width: ${progressPercentage}%`"
            >
              {{ Math.round(progressPercentage) }}%
            </div>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';
import axios from 'axios';
import ProfileQuestionsFactory from '../../Components/ProfileQuestions/Factory.vue';

export default {
  components: {
    ProfileQuestionsFactory,
  },

  props: {
    questions: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    let memberAnswers = [];
    props.questions.forEach(question => {
      memberAnswers[question.id] = [];
    });

    return {
      activeQuestionKey: ref(0),
      memberAnswers: ref(memberAnswers),
      updating: ref([]),
      validationErrorMessages: ref({}),
    };
  },

  computed: {
    activeQuestion() {
      return this.questions[this.activeQuestionKey] ?? null;
    },

    progressPercentage() {
      if (this.activeQuestionKey === 0) {
        return 0;
      }

      const percentage = (this.activeQuestionKey / this.questions.length) * 100;

      return percentage > 100 ? 100 : percentage;
    },
  },

  methods: {
    validationError(questionId, message) {
      this.validationErrorMessages[questionId] = message;
    },

    updateQuestion(questionId, answers) {
      this.updating.push(questionId);

      axios.post(this.route('frontend.api.profile-questions.update-question-answer', {profileQuestion: questionId}), {
        answers: answers,
      })
        .then(() => {
          this.activeQuestionKey++;
          this.updating.splice(this.updating.indexOf(questionId), 1);
        });
    },
  },
};
</script>

<script setup>
</script>
<script setup>
</script>
<script setup>
</script>

<template>
  <div class="container">
    <MobileBreadcrumbs
      :pages="[
        {name: __('games.index.title'), href: route('frontend.games.index')},
      ]"
      :current-page="game.name"
    />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4">
        <DesktopBreadcrumbs
          :pages="[
            {name: __('games.index.title'), href: route('frontend.games.index')},
          ]"
          :current-page="game.name"
        />

        <h1 class="text-white font-bold font-serif mb-4 text-center md:text-red md:text-3xl md:text-left">
          {{ game.name }}
        </h1>

        <div class="bg-white shadow-box rounded p-3 md:p-0 mb-4 md:mb-8 md:shadow-none w-full">
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div>
              <img
                :src="game.media.url"
                :alt="game.name"
                :title="game.name"
                class="block md:mb-4"
              >

              <template v-if="typeof highscoreToday === 'object' && 'score' in highscoreToday">
                <div class="hidden md:block rounded border text-center mb-4 md:mb-8 px-4 py-2">
                  <div class="font-bold">
                    {{ __('games.show.highscore-today.today') }}
                  </div>
                  <div class="font-bold text-red text-4xl my-3">
                    {{ highscoreToday.score }}
                  </div>
                  <div class="text-xs text-gray-300">
                    {{ __('games.show.highscore-today.by', {name: highscoreToday.username}) }}
                  </div>
                </div>
              </template>
            </div>

            <div>
              <div class="relative mb-4">
                <div
                  class="overflow-y-hidden"
                  :class="{ 'max-h-[300px]': !readMore }"
                >
                  <!-- eslint-disable vue/no-v-html -->
                  <div
                    class="content"
                    v-html="game.description"
                  />
                  <!-- eslint-enable -->
                </div>

                <button
                  v-show="!readMore"
                  class="cursor-pointer absolute w-full text-center text-lg text-red pt-16 mb-8 -bottom-8 bg-gradient-to-t from-white"
                  @click="readMore = true"
                >
                  {{ __('games.show.read-more') }}
                </button>
              </div>

              <div class="text-bold text-xl mb-4">
                {{ __('games.show.win-reward', {reward: game.reward}) }}
              </div>

              <template v-if="memberCanPlayGame">
                <FullscreenModal ref="game-modal">
                  <template #modal-button="{ openModal }">
                    <button
                      class="btn md:!w-[auto] text-base md:text-xl md:inline-flex !py-2 !px-12 mx-auto"
                      @click="openModal"
                    >
                      {{ __('games.show.play-button') }}

                      <span class="material-symbols-outlined text-white font-bold">
                        chevron_right
                      </span>
                    </button>
                  </template>

                  <template #modal-content>
                    <iframe
                      :src="game.url"
                      class="h-[100dvh] w-full"
                    />
                  </template>
                </FullscreenModal>
              </template>
              <template v-else>
                <div class="text-red">
                  {{ __('games.show.maximum-games-today-reached') }}
                </div>
              </template>
            </div>
          </div>
        </div>

        <template v-if="typeof highscoreToday === 'object' && 'score' in highscoreToday">
          <div class="md:hidden bg-white rounded border text-center mb-4 md:mb-8 px-4 py-2">
            <div class="font-bold">
              {{ __('games.show.highscore-today.today') }}
            </div>
            <div class="font-bold text-red text-4xl my-3">
              {{ highscoreToday.score }}
            </div>
            <div class="text-xs text-gray-300">
              {{ __('games.show.highscore-today.by', {name: highscoreToday.username}) }}
            </div>
          </div>
        </template>

        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div class="rounded w-full">
            <h2 class="text-white md:text-black font-bold font-serif mb-4 text-center md:text-left">
              {{ __('games.show.winners.title') }}
            </h2>

            <div class="flex flex-wrap text-white md:text-black mt-4 mb-2 text-xs md:text-sm ">
              <span class="w-3/12 font-bold pl-3 md:pl-0">
                {{ __('games.show.winners.date') }}
              </span>
              <span class="w-2/12 font-bold pl-3">
                {{ __('games.show.winners.score') }}
              </span>
              <span class="w-3/12 font-bold pl-3">
                {{ __('games.show.winners.amount') }}
              </span>
              <span class="w-4/12 font-bold pl-3">
                {{ __('games.show.winners.name') }}
              </span>
            </div>

            <div class="bg-white rounded md:rounded-none divide-solid divide-y divide-yellow-500 mb-3 md:mb-6 md:border-b md:border-t md:border-yellow-500">
              <template v-if="winners.length > 0">
                <template
                  v-for="(highscore, i) in itemsForWinnersPage"
                  :key="`highscore-${i}`"
                >
                  <div class="flex flex-wrap py-2">
                    <span class="pl-3 w-3/12 font-bold text-xs leading-5 md:text-base md:pl-0">
                      {{ highscore.date }}
                    </span>
                    <span class="w-2/12 text-xs leading-5 md:text-base pl-3">
                      {{ highscore.score }}
                    </span>
                    <span class="w-3/12 text-xs leading-5 md:text-base pl-3">
                      {{ highscore.reward }}
                    </span>
                    <span class="w-4/12 text-xs leading-5 md:text-base pl-3">
                      {{ highscore.username }}
                    </span>
                  </div>
                </template>
              </template>
              <template v-else>
                <div class="flex flex-wrap py-2">
                  <span class="w-full text-center text-xs leading-5 md:text-base">
                    {{ __('games.show.winners.no-results') }}
                  </span>
                </div>
              </template>
            </div>

            <DynamicPagination
              v-if="winnersNeedsPagination"
              :total-pages="totalHighscorePages"
              @paginate="(newPage) => winnersPage = newPage"
            />
          </div>

          <div class="rounded w-full">
            <h2 class="text-white md:text-black font-bold font-serif mb-4 text-center md:text-left">
              {{ __('games.show.member-sessions.title') }}
            </h2>

            <div class="flex flex-wrap text-white md:text-black mt-4 mb-2 text-xs md:text-sm ">
              <span class="w-9/12 font-bold pl-3 md:pl-0">
                {{ __('games.show.member-sessions.date') }}
              </span>
              <span class="w-3/12 font-bold pl-3">
                {{ __('games.show.member-sessions.score') }}
              </span>
            </div>

            <div class="bg-white rounded md:rounded-none divide-solid divide-y divide-yellow-500 mb-3 md:mb-6 md:border-b md:border-t md:border-yellow-500">
              <template v-if="memberSessions.length > 0">
                <template
                  v-for="(session, i) in itemsForMemberSessionPage"
                  :key="`session-${i}`"
                >
                  <div class="flex flex-wrap py-2">
                    <span class="pl-3 w-9/12 text-xs font-bold leading-5 md:text-base md:pl-0">
                      {{ session.date }}
                    </span>
                    <span class="w-3/12 text-xs leading-5 md:text-base pl-3">
                      {{ session.score }}
                    </span>
                  </div>
                </template>
              </template>
              <template v-else>
                <div class="flex flex-wrap py-2">
                  <span class="w-full text-center text-xs leading-5 md:text-base">
                    {{ __('games.show.member-sessions.no-results') }}
                  </span>
                </div>
              </template>
            </div>

            <DynamicPagination
              v-if="memberSessionsNeedsPagination"
              :total-pages="totalMemberSessionPages"
              @paginate="(newPage) => memberSessionsPage = newPage"
            />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import DynamicPagination from '../../../Components/DynamicPagination.vue';
import FullscreenModal from '../../../Components/FullscreenModal.vue';

export default {
  components: {
    DynamicPagination, FullscreenModal,
  },

  props: {
    game: {
      type: Object,
      required: true,
    },

    gamesUrl: {
      type: String,
      required: true,
    },

    highscoreToday: {
      type: Object,
      required: true,
    },

    winners: {
      type: Array,
      required: true,
    },

    memberSessions: {
      type: Array,
      required: true,
    },

    memberCanPlayGame: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    const perPage = 5;
    const totalHighscorePages = Math.ceil(this.winners.length / perPage);
    const totalMemberSessionPages = Math.ceil(this.memberSessions.length / perPage);

    return {
      readMore: false,

      winnersPage: 0,
      memberSessionsPage: 0,
      perPage,
      totalHighscorePages,
      totalMemberSessionPages,
      winnersNeedsPagination: totalHighscorePages > 1,
      memberSessionsNeedsPagination: totalMemberSessionPages > 1,
    };
  },

  computed: {
    itemsForWinnersPage() {
      const start = this.winnersPage * this.perPage;
      const end = start + this.perPage;

      return this.winners.slice(start, end);
    },

    itemsForMemberSessionPage() {
      const start = this.memberSessionsPage * this.perPage;
      const end = start + this.perPage;

      return this.memberSessions.slice(start, end);
    },
  },

  mounted() {
    window.addEventListener('message', this.handleMessage);
  },

  beforeUnmount() {
    window.removeEventListener('message', this.handleMessage);
  },

  methods: {
    handleMessage(event) {
      if (event.origin !== this.gamesUrl) {
        return;
      }

      if (event.data.type === 'finish-session') {
        this.$inertia.reload({ only: ['highscoreToday', 'winners', 'memberSessions', 'memberCanPlayGame'] });
      }

      if (event.data.type === 'close-modal') {
        this.$refs['game-modal'].closeModal();
      }
    },
  },
};
</script>

<template>
  <div class="container">
    <MobileBreadcrumbs
      :pages="[
        { name: __('profile-questions.index.title'), href: route('frontend.profile-questions.index') },
      ]"
      :current-page="__('profile-questions.completed.title')"
    />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4 mb-6">
        <DesktopBreadcrumbs
          :pages="[
            { name: __('profile-questions.index.title'), href: route('frontend.profile-questions.index') },
          ]"
          :current-page="__('profile-questions.completed.title')"
        />

        <div class="bg-white shadow-box rounded p-3 md:p-0 mb-4 md:shadow-none w-full">
          <h1 class="font-serif text-red mb-2 font-bold md:text-3xl">
            {{ __('profile-questions.completed.title') }}
          </h1>

          <form
            class="w-full flex md:justify-end"
            @submit.prevent="filter(true)"
          >
            <div class="relative w-full mb-4">
              <div
                class="border-yellow bg-white flex flex-wrap border-3 rounded-xl"
              >
                <input
                  v-model="filterForm.q"
                  type="text"
                  class="w-11/12 border-none appearance-none focus:border-opacity-0 focus:ring-0 text-black font-serif rounded-tl-xl rounded-bl-xl py-3"

                  :placeholder="__('profile-questions.completed.search')"
                  @keyup="filter(false)"
                >

                <button
                  type="submit"
                  class="absolute inset-y-0 right-0 pr-4 flex items-center"
                >
                  <span
                    v-if="filterForm.processing"
                    class="text-red material-symbols-outlined animate-spin"
                  >
                    autorenew
                  </span>
                  <span
                    v-else
                    class="text-red material-symbols-outlined"
                  >
                    search
                  </span>
                </button>
              </div>
            </div>
          </form>
        </div>

        <section
          v-if="questions.data && questions.data.length > 0"
          class="flex flex-col mb-6 space-y-4"
        >
          <div
            v-for="question in questions.data"
            :key="question.id"
            class="bg-white shadow-box rounded p-3"
          >
            <ProfileQuestionsFactory
              v-model="memberAnswers[question.id]"
              :question="question"
              @validation-error="validationError(question.id, $event)"
            />

            <div
              v-show="validationErrorMessages[question.id]"
              class="mt-2 text-red text-sm"
            >
              *{{ validationErrorMessages[question.id] }}
            </div>

            <button
              class="btn text-sm md:text-base mt-4 !w-[auto] !px-2"
              :disabled="updating.includes(question.id) || validationErrorMessages[question.id]"
              :loading="updating.includes(question.id)"
              @click="updateQuestion(question.id, memberAnswers[question.id])"
            >
              {{ __('profile-questions.questions.edit') }}
              <span
                v-if="updating.includes(question.id)"
                class="material-symbols-outlined text-white animate-spin font-bold"
              >
                autorenew
              </span>
              <span
                v-else
                class="material-symbols-outlined text-white font-bold"
              >
                chevron_right
              </span>
            </button>
          </div>

          <div
            v-if="questions.meta.pagination.links.length > 3"
            class="text-sm w-full mt-4 md:mt-12"
          >
            <div class="border-gray-200 border flex flex-wrap justify-center divide-solid divide-x divide-gray-200 items-center bg-white md:w-3/6 md:mx-auto">
              <template
                v-for="(link, i) in questions.meta.pagination.links ?? []"
                :key="`page-link-${i}`"
              >
                <Link
                  v-if="link.label === __('pagination.previous') && questions.meta.pagination.prev_page_url"
                  :href="questions.meta.pagination.prev_page_url"
                  class="w-1/12 text-center py-2"
                  :class="{
                    'font-bold': link.active,
                    'pointer-events-none': questions.meta.pagination.current_page === 1,
                  }"
                >
                  <span class="material-symbols-outlined align-middle">
                    chevron_left
                  </span>
                </Link>
                <Link
                  v-else-if="link.label === __('pagination.next') && questions.meta.pagination.next_page_url"
                  class="w-1/12 text-center py-2"
                  :href="questions.meta.pagination.next_page_url"
                  :class="{
                    'font-bold': link.active,
                    'pointer-events-none': questions.meta.pagination.current_page === questions.meta.pagination.last_page,
                  }"
                >
                  <span class="material-symbols-outlined align-middle">
                    chevron_right
                  </span>
                </Link>
                <Link
                  v-else-if="link.label !== __('pagination.previous') && link.label !== __('pagination.next')"
                  :href="link.url"
                  class="w-1/12 text-center py-2"
                  :class="{ 'font-bold': link.active }"
                >
                  {{ link.label }}
                </Link>
              </template>
            </div>
          </div>
        </section>

        <section
          v-else
          class="mb-6"
        >
          <p class="text-sm md:text-base text-center py-2">
            {{ __('profile-questions.completed.no-data') }}
          </p>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';
import {useForm} from '@inertiajs/vue3';
import axios from 'axios';
import ProfileQuestionsFactory from '../../Components/ProfileQuestions/Factory.vue';

export default {
  components: {
    ProfileQuestionsFactory,
  },

  props: {
    questions: {
      type: Object,
      required: true,
    },

    filters: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const updateMemberAnswers = function(questions) {
      let memberAnswers = [];
      if (questions.data) {
        questions.data.forEach(question => {
          memberAnswers[question.id] = null;
          if (question.memberAnswers) {
            memberAnswers[question.id] = question.memberAnswers.map(memberAnswer => {
              return memberAnswer.profile_answer_id;
            });
          }
        });
      }

      return memberAnswers;
    };

    let memberAnswers = updateMemberAnswers(props.questions);

    return {
      filterTimer: null,
      filterForm: useForm({
        q: props.filters.q ?? null,
      }),

      memberAnswers: ref(memberAnswers),
      updating: ref([]),
      validationErrorMessages: ref({}),
      updateMemberAnswers: updateMemberAnswers,
    };
  },

  methods: {
    validationError(questionId, message) {
      this.validationErrorMessages[questionId] = message;
    },

    updateQuestion(questionId, answers) {
      this.updating.push(questionId);

      axios.post(this.route('frontend.api.profile-questions.update-question-answer', {profileQuestion: questionId}), {
        answers: answers,
      })
        .then(() => {
          this.updating.splice(this.updating.indexOf(questionId), 1);
        });
    },

    filter(direct) {
      if (this.filterTimer) {
        clearTimeout(this.filterTimer);
        this.filterTimer = null;
      }
      this.filterTimer = setTimeout(() => {
        this.filterForm.get(this.route('frontend.profile-questions.completed'), {
          preserveScroll: true,
          preserveState: true,
          only: ['questions', 'filters'],
          onSuccess: () => this.memberAnswers = this.updateMemberAnswers(this.questions),
        });
      }, direct ? 0 : 800);
    },
  },
};
</script>


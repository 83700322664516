<template>
  <div class="container">
    <MobileBreadcrumbs :current-page="__('mailings.breadcrumb-title')" />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md">
      <Sidebar />

      <section class="md:pl-6 w-full md:w-3/4 mb-6">
        <DesktopBreadcrumbs :current-page="__('mailings.breadcrumb-title')" />

        <h1 class="font-bold font-serif text-red hidden md:block text-3xl mb-4">
          {{ __('mailings.title') }}
        </h1>

        <WebshopSuggestions :webshop-suggestions="webshopSuggestions" />

        <h2 class="text-white font-bold font-serif text-center md:text-black md:text-2xl md:text-left">
          {{ __('mailings.unread-title') }}
        </h2>
        <p class="text-white text-center block md:hidden">
          {{ __('mailings.unread-subtitle') }}
        </p>

        <div class="flex flex-wrap text-white mt-4 mb-2 md:text-black text-xs md:text-sm">
          <span class="w-6/12 md:w-8/12 font-bold pl-3">
            {{ __('mailings.table.subject-column') }}
          </span>
          <span class="w-3/12 md:w-2/12 font-bold pl-3">
            {{ __('mailings.table.reward-column') }}
          </span>
          <span class="w-3/12 md:w-2/12 font-bold pl-3">
            {{ __('mailings.table.valid-until-column') }}
          </span>
        </div>

        <div class="bg-white rounded md:rounded-none divide-solid divide-y divide-yellow-500 mb-6 md:border-b md:border-t md:border-yellow-500">
          <template v-if="unreadMailings.length > 0">
            <template
              v-for="(mailing, i) in unreadMailings"
              :key="`unread-mailing-${i}`"
            >
              <div class="flex flex-wrap py-2">
                <Link
                  :href="route('frontend.mailings.show', mailing.id)"
                  class="line-clamp-2 md:line-clamp-1 pl-3 w-6/12 md:w-8/12 leading-5 text-red"
                >
                  <span
                    v-if="mailing.is_boosted"
                    class="text-2xs bg-red border border-yellow shadow-double-tip text-white font-bold rounded-sm px-1 mr-2 inline -mt-1"
                  >
                    {{ __('mailings.booster-label') }}
                  </span>
                  <span class="inline underline hover:no-underline">
                    {{ mailing.subject }}
                  </span>
                </Link>
                <span class="w-3/12 md:w-2/12 text-xs md:text-base pl-3">
                  {{ mailing.reward }}
                </span>
                <span class="w-3/12 md:w-2/12 text-xs md:text-base pl-3">
                  {{ mailing.valid_until }}
                </span>
              </div>
            </template>
          </template>
          <template v-else>
            <div class="w-full text-xs text-center md:text-base py-2">
              {{ __('mailings.table.no-results') }}
            </div>
          </template>
        </div>

        <h2 class="text-white font-bold font-serif mb-2 text-center md:text-black md:text-2xl md:text-left md:mt-8 block">
          {{ __('mailings.read-title') }}
        </h2>

        <div class="flex flex-wrap text-white mt-4 mb-2 md:text-black text-xs md:text-sm">
          <span class="w-6/12 md:w-8/12 font-bold pl-3">
            {{ __('mailings.table.subject-column') }}
          </span>
          <span class="w-3/12 md:w-2/12 font-bold pl-3">
            {{ __('mailings.table.reward-column') }}
          </span>
          <span class="w-3/12 md:w-2/12 font-bold pl-3">
            {{ __('mailings.table.valid-until-column') }}
          </span>
        </div>

        <div class="bg-white rounded md:rounded-none divide-solid divide-y divide-yellow-500 mb-6 md:border-b md:border-t md:border-yellow-500">
          <template v-if="readMailings.length > 0">
            <template
              v-for="(mailing, i) in readMailings"
              :key="`unread-mailing-${i}`"
            >
              <div class="flex flex-wrap py-2">
                <Link
                  :href="route('frontend.mailings.show', mailing.id)"
                  class="line-clamp-2 md:line-clamp-1 pl-3 w-6/12 md:w-8/12 leading-5 text-black"
                >
                  <span
                    v-if="mailing.is_boosted"
                    class="text-2xs bg-red border border-yellow shadow-double-tip text-white font-bold rounded-sm px-1 mr-2 inline -mt-1"
                  >
                    {{ __('mailings.booster-label') }}
                  </span>
                  <span class="inline underline hover:no-underline">
                    {{ mailing.subject }}
                  </span>
                </Link>
                <span class="w-3/12 md:w-2/12 text-xs md:text-base pl-3">
                  {{ mailing.reward }}
                </span>
                <span class="w-3/12 md:w-2/12 text-xs md:text-base pl-3">
                  {{ mailing.valid_until }}
                </span>
              </div>
            </template>
          </template>
          <template v-else>
            <div class="w-full text-xs text-center md:text-base py-2">
              {{ __('mailings.table.no-results') }}
            </div>
          </template>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import WebshopSuggestions from '../../Components/WebshopSuggestions.vue';

export default {
  components: {WebshopSuggestions},
  props: {
    unreadMailings: {
      type: Array,
      required: true,
    },

    readMailings: {
      type: Array,
      required: true,
    },

    webshopSuggestions: {
      type: Array,
      required: true,
    },
  },
};
</script>
